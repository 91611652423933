/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { Dict } from "../models/capture";
import { IResultHeader } from "../models/result";

const useStageResult = (stagePayload: Dict | string | undefined) => {
  const [headersState, setHeadersState] = useState<IResultHeader[]>([]);
  const [fieldsState, setFieldsState] = useState<IResultHeader[]>([]);
  const [imagesState, setImagesState] = useState<IResultHeader[]>([]);
  const [authsState, setAuthsState] = useState<IResultHeader[]>([]);

  useEffect(() => {

    const parsePayload = () => {
      if (typeof stagePayload === 'string') stagePayload = JSON.parse(stagePayload) as Dict;

      const tempHeaders: IResultHeader[]= [];
      const tempFields: IResultHeader[]= [];
      const tempImages: IResultHeader[]= [];
      const tempAuths: IResultHeader[]= [];

      for (let key in stagePayload) {
        if((stagePayload[key] !== undefined && stagePayload[key] !== null) && stagePayload.hasOwnProperty(key)){
          if (typeof stagePayload[key] === 'string' || typeof stagePayload[key] === 'number' || typeof stagePayload[key] === 'boolean') {
            tempHeaders.push({title: key, message: stagePayload[key].toString()});
          } else if (typeof stagePayload[key] === 'object') {
            if(key === "fields" || key === "gestures") {
              let stageFields = stagePayload[key];
              for (let fieldKey in stageFields) {
                if ((stageFields[fieldKey] !== undefined && stageFields[fieldKey] !== null) && stageFields.hasOwnProperty(fieldKey) 
                && ((typeof stageFields[fieldKey] === 'string' || typeof stageFields[fieldKey] === 'number' || typeof stageFields[fieldKey] === 'boolean'))) 
                  tempFields.push({title: fieldKey, message: stageFields[fieldKey].toString()});
              }
            } else if (key === "images") {
              let stageImages = stagePayload[key];
              for (let imageKey in stageImages) {
                if ((stageImages[imageKey] !== undefined && stageImages[imageKey] !== null) && stageImages.hasOwnProperty(imageKey) 
                && ((typeof stageImages[imageKey] === 'string' || typeof stageImages[imageKey] === 'number' || typeof stageImages[imageKey] === 'boolean'))) 
                  tempImages.push({title: imageKey, message: stageImages[imageKey].toString()});
              }
            } else if (key === "auth") {
              let stageAuths = stagePayload[key];
              for (let authKey in stageAuths) {
                if ((stageAuths[authKey] !== undefined && stageAuths[authKey] !== null) && stageAuths.hasOwnProperty(authKey) 
                && ((typeof stageAuths[authKey] === 'string' || typeof stageAuths[authKey] === 'number' || typeof stageAuths[authKey] === 'boolean'))) 
                  tempAuths.push({title: authKey, message: stageAuths[authKey].toString()});
              }
            } 
          }
        }
      }

      setHeadersState(tempHeaders);
      setFieldsState(tempFields);
      setImagesState(tempImages);
      setAuthsState(tempAuths);
    }

    if(stagePayload) parsePayload(); 

  }, [stagePayload]);

  return {headersState, fieldsState, imagesState, authsState}
}

export default useStageResult;