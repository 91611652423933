/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { Dict } from "../models/capture";
import { IResultHeader } from "../models/result";

const useResult = (result: Dict | string) => { // TODO:: chang dict to resObj

  const [headersState, setHeadersState] = useState<IResultHeader[]>([]);
  const [stagesState, setStagesState] = useState<any[]>([]); // TODO:: change any?

  useEffect(() => {

    const parseResult = () => {
      if (typeof result === 'string') result = JSON.parse(result) as Dict;

      const tempHeaders: IResultHeader[]= [];

      for (let key in result) {
        if ((result[key] !== undefined && result[key] !== null) && result.hasOwnProperty(key)) {
          if (typeof result[key] === 'string' || typeof result[key] === 'number' || typeof result[key] === 'boolean') {
            tempHeaders.push({title: key, message: result[key].toString()});
          } else if (Array.isArray(result[key]) && key === "stages") {
            setStagesState(result[key].filter((elem: any) => { // any?
              return elem.payload && (Object.keys(elem.payload).length > 0);
            }));
          }
        }
      }

      setHeadersState(tempHeaders);
    }

    parseResult();

  }, [result]);

  return {headersState,stagesState};
}

export default useResult;