import React, { ReactNode } from "react";

import { GestureBarIconState } from '../CCGestureSvg/CCGestureSvg';

import CCGestureBarIconView from './CCGestureBarIcon.view';

interface Props {
  children?: ReactNode;
  readonly iconName: string[];
  readonly state: GestureBarIconState;
  readonly gestureIconPassColor: string;
  readonly counter?: number | string;  
}

const CCGestureBarIcon: React.FC<Props> = (props: React.PropsWithChildren<Props>) => (
  <CCGestureBarIconView
    iconName={props.iconName}
    state={props.state}
    gestureIconPassColor={props.gestureIconPassColor}
    counter={props.counter}
    children={props.children}
    />
);

CCGestureBarIcon.displayName = 'CCGestureBarIcon';
CCGestureBarIcon.defaultProps = {};

export default CCGestureBarIcon;
