
const useQuery = (queryOptions: string[] = []) => {
  const history = window.location.search;
  const queryParams = new URLSearchParams(history);
  const queryObject = queryOptions.reduce<{[key: string]:string | null}>((acc, option) => {
    if (!acc[option]) {
      acc[option] = null;
    }
    acc[option] = queryParams.get(`${option}`);
    return acc;
  }, {});

  return queryObject;
};
  
  export default useQuery;