import React, { useEffect, useState } from 'react';

import { ISize } from '../../../../../hooks/use-window-size';
import { IRect, IStylesCustomizations, rectZero } from '../../../../../models/capture';
import CaptureImgSrc from "../../../../../assets/svg/capture.svg"

import SingleCaptureOverlayView from './SingleCaptureOverlay.view';

interface Props {
  readonly size: ISize;
  readonly styles: IStylesCustomizations;
  readonly isStream: boolean;
  readonly instructionLabelText: string;
  readonly isButtonDisable: boolean;
  readonly captureClickHandler: () => void;
 }

const SingleCaptureOverlay: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  
  const [paintedRectTargetState, setPaintedRectTargetState] = useState<IRect>(rectZero);
  const [instructionTopState, setInstructionTopStateState] = useState<number>(0);
  const [instructionFontSizeState, setInstructionFontSizeState] = useState<number>(21);
  const [buttonRectState, setButtonRectState] = useState<IRect>(rectZero);

  useEffect(() => {

    if (props.size.width > 0 && props.size.height > 0) {
      let scanFrameMaxWidth = props.size.width;
      let scanFrameMaxHeight = props.size.height;

      const isLandscape = scanFrameMaxWidth > scanFrameMaxHeight;
      if (isLandscape && scanFrameMaxWidth > 1024 && scanFrameMaxHeight > 553) {
        scanFrameMaxWidth = 894;
        scanFrameMaxHeight = 503;
      }

      let frameRectPercent = isLandscape ? 75 : 84;
      let cardRatio = 1.5;

      let heightMAX = scanFrameMaxHeight * frameRectPercent / 100;
      let widthMAX = scanFrameMaxWidth * frameRectPercent / 100;
      let newH = heightMAX;
      let newW = heightMAX * cardRatio;
      if (newW > widthMAX && cardRatio !== 0) {
          newW = widthMAX;
          newH = newW / cardRatio
      }

      let topPaintedRectTarget: number;
      let instructionLabelContainerTop: number;

      if (isLandscape) {
        setInstructionFontSizeState(Math.min(scanFrameMaxWidth * 0.02, 21));
        topPaintedRectTarget = scanFrameMaxHeight / 2 - newH / 2 + scanFrameMaxHeight * 0.05;
        instructionLabelContainerTop = topPaintedRectTarget - scanFrameMaxWidth * 0.07;
      } else {
        setInstructionFontSizeState(Math.min(scanFrameMaxHeight * 0.027, 21));
        topPaintedRectTarget = scanFrameMaxHeight / 2 - newH / 2 - newH * 0.15;
        instructionLabelContainerTop = topPaintedRectTarget - scanFrameMaxHeight * 0.15;
      }

      const paintedRectTarget:IRect = {
        left: scanFrameMaxWidth / 2 - newW / 2,
        top: topPaintedRectTarget,
        width: newW,
        height: newH,
      }

      const buttonSide = 60;
      if (isLandscape) {
        if (props.size.width > 1024 && props.size.height > 553) {
          setButtonRectState(() => ({
            top: 282 - buttonSide/2 ,
            left: 894 - ((894 - paintedRectTarget.width)/4) - buttonSide/2 ,
            height: buttonSide,
            width: buttonSide,
          }));
        }
        else {
          setButtonRectState(() => ({
            top: props.size.height/2 - buttonSide/2,
            left: props.size.width - (props.size.width - paintedRectTarget.width)/4,
            height: buttonSide,
            width: buttonSide,
          }));
        }
      } else {
        setButtonRectState(() => ({
          top: props.size.height - (buttonSide * 1.5),
          left: paintedRectTarget.left + ((paintedRectTarget.width - buttonSide) / 2),
          height: buttonSide,
          width: buttonSide,
        }));
      }

      setPaintedRectTargetState(paintedRectTarget);
      setInstructionTopStateState(instructionLabelContainerTop);
    }
  }, [props.size])

  const captureClickHandler = () => {
    props.captureClickHandler();
  }

  return (
    <SingleCaptureOverlayView
      styles={props.styles}
      paintedRectTarget={paintedRectTargetState}
      instructionTop={instructionTopState}
      instructionLabelText={props.instructionLabelText}
      instructionFontSize={instructionFontSizeState}
      buttonRect={buttonRectState}
      captureButtonImage={CaptureImgSrc}
      isButtonDisable={props.isButtonDisable}
      captureClickHandler={captureClickHandler}
      children={props.children}
      />
  );
};

SingleCaptureOverlay.displayName = 'SingleCaptureOverlay';
SingleCaptureOverlay.defaultProps = {};

export default SingleCaptureOverlay;
