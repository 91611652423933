import CardFlipImgSrc from './../assets/gif/card_flip.gif'
import CenterToLeftImgSrc from './../assets/gif/liveness_left_once.gif'
import CenterToRightImgSrc from './../assets/gif/liveness_right_once.gif'
import TiltCardImgSrc from './../assets/gif/tilt.gif'
import CenterImgSrc from './../assets/svg/liveness_center.svg'
import CheckmarkImgSrc from './../assets/svg/success_checkmark.svg'

export type Dict = { [key: string]: any };

export const cameraSize = {
  bigSize: 1280,
  smallSize: 720,
  kioskLivenessBigSize: 3840,
  kioskLivenessSmallSize: 2160,
}

export interface IStylesCustomizations {
  progressLabelColor: string;
  progressLabelOpacity: string;
  progressLabelFontFamily: string;
  recordingButtonTitleFontFamily: string;
  recordingNumbersFontFamily: string;
  scanLineColor: string;
  instructionLabelColor: string;
  instructionLabelFontFamily: string;
  instructionLabelBg: string;
  instructionLabelBorderRadius: string;
  gestureIconPassColor: string;
}

export const defaultStyles: IStylesCustomizations = {
  progressLabelColor: '#fff',
  progressLabelOpacity: '0.46',
  progressLabelFontFamily: 'Roboto Medium, serif',
  recordingButtonTitleFontFamily: 'Roboto Medium, serif',
  recordingNumbersFontFamily: 'Roboto Bold, serif',
  scanLineColor: '#0A67FECC',
  instructionLabelColor: '#fff',
  instructionLabelFontFamily: 'Roboto Medium, serif',
  instructionLabelBg: '#3346FF',
  instructionLabelBorderRadius: '8px',
  gestureIconPassColor: '#fff',
}

export interface IImagesCustomizations {
  CardFlipImgSrc: string;
  CenterToLeftImgSrc: string;
  CenterToRightImgSrc: string;
  CenterImgSrc: string;
  TiltCardImgSrc: string;
  Checkmark: string;
}

export const defaultImages: IImagesCustomizations = {
  CardFlipImgSrc: CardFlipImgSrc,
  CenterToLeftImgSrc: CenterToLeftImgSrc,
  CenterToRightImgSrc: CenterToRightImgSrc,
  CenterImgSrc: CenterImgSrc,
  TiltCardImgSrc: TiltCardImgSrc,
  Checkmark: CheckmarkImgSrc,
};

export const defaultMessageTranslations: Dict = {
  "97": "No_cheque_detected",
  "98": "Cheque_not_in_frame",
  "99": "Cheque_in_frame",
  "100": "Wrong_side",
  "101": "No_card_detected",
  "102": "Card_not_in_frame",
  "103": "Card_detected",
  "104": "Card_too_far",
  "149": "Unexpected_response_received",
  "150": "Avoid_moving",
  "151": "Look_left",
  "152": "Look_right",
  "153": "Look_straight",
  "154": "face_too_close",
  "155": "Thinking_about_it...",
  "156": "No_face_found",
  "157": "Too_many_faces",
  "158": "Stay_in_the_frame",
  "159": "Come_closer",
  "160": "You're_too_close",
  "161": "Broken_image",
  "162": "Hold_your_phone_straight",
  "163": "Stay_in_the_frame",
  "164": "Frame_received",
  "165": "Face_not_properly_illuminated",
  "166": "Face_not_in_focus",
  "167": "Face_mask_detected",
  "168": "Not_enough_space_around_image_for_full_crop",
  "250": "Scanning_..._please_wait",
}

export interface ITitles {
  SCAN_YOUR_CARD?: string
  SCAN_YOUR_CARD_FRONT_SIDE?: string
  SCAN_YOUR_CARD_BACK_SIDE?: string
  DONE?: string
  TURN_ID_OVER?: string
  TILT_YOUR_CARD?: string
  PASSIVE?: string
  CENTER?: string
  RIGHT?: string
  LEFT?: string
  RECORD_OTP?: string
  RECORD_STT?: string
  RECORD_GESTURE?: string
  FINISH_RECORD?: string
  ERROR?: string
}

export const defaultPreStageTranslations : ITitles = {
  SCAN_YOUR_CARD: "Scan_ID",
  SCAN_YOUR_CARD_FRONT_SIDE: "Scan_ID_front_side",
  SCAN_YOUR_CARD_BACK_SIDE: "Scan_ID_back_side",
  DONE: "Done!",
  TURN_ID_OVER: "Turn_ID_over",
  TILT_YOUR_CARD: "Tilt_your_card",
  PASSIVE: "Place_your_face_within_the_frame",
  CENTER: "Look_straight",
  RIGHT: "Turn_your_head_to_the_right",
  LEFT: "Turn_your_head_to_the_left",
  RECORD_OTP: "Read_the_code_out_loud",
  RECORD_STT: "Read_the_sentence_out_loud",
  RECORD_GESTURE: "Reproduce_the_symbols_in order_with_your_hand",
  FINISH_RECORD: "Finish_Recording",
  ERROR: "Error",
}

export interface IPaintedRectTargetStylesCustomizations {
  borderColor: string;
  borderWidth: number;
  radius: {
    tl: number;
    tr: number;
    br: number;
    bl: number;
  };
}

export const defaultPaintedRectTargetStyles:IPaintedRectTargetStylesCustomizations = {
  borderColor: 'white',
  borderWidth: 4,
  radius: {
    tl: 16,
    tr: 16,
    br: 16,
    bl: 16,
  }
}

export interface IConnectionParams {
  url?: string;
  extraData: {
    libraryName: string;
    caseID: string;
    webhookInjectionParams?: Dict;
    sttLanguage?: string;
    flowConfigName?: string;
    clientTranslationFileName?: string;
    stagesConfiguration?: {
      otp: {
        number: string;
      };
    };
  };
  relativeUrls? : {
    connectionUrl?: string;
    postConnectionUrl?: string;
  };
}

export interface ISingleCaptureParams {
  url: string;
  clientTranslationFileName?: string;
  instructionText: string;
}

export interface IRect {
  top: number;
  left: number;
  width: number;
  height: number;
}

export const rectZero: IRect = {
  top: 0,
  left: 0,
  width: 0,
  height: 0,
}

export interface ICPalette {
  topLeft: string | CanvasGradient | CanvasPattern;
  topRight: string | CanvasGradient | CanvasPattern;
  bottomRight: string | CanvasGradient | CanvasPattern;
  bottomLeft: string | CanvasGradient | CanvasPattern;
}

export enum StageCodes {
  Empty,
  // OCR
  Any,
  FrontSide,
  BackSide,
  Video,
  // Liveness
  Passive,
  Center,
  Left,
  Right,
  OTP,
  STT,
  Gestures,
  // Common
  Completed,
  Error,
  Timeout,
}

export interface IStageParams {
  titleText: string,
  animationTitleText: string,
  animationDuration: number,
  currentImage: string,
}

export interface ITranslationScan {
  scanCard?: string
  scanFront?: string,
  scanBack?: string,
  done?: string,
  turnIdOver?: string,
  tiltCard?: string,
  passive?: string,
  center?: string,
  right?: string,
  left?: string,
  recordOTP?: string,
  recordSTT?: string,
  recordGesture?: string,
  finishRecord?: string,
  error?: string,
}

export interface ITranslationRes {
  generalMessages: Dict,
  scan: ITranslationScan,
}

//motion Variant
export const visibilityVariant = (display: string, duration: number, needToDisplayNone: boolean) => {
  let hidden : any = {
    opacity: 0,
    transition: {
        duration: duration
    },
  }
  if (needToDisplayNone) {
    hidden = {
      opacity: 0,
      transition: {
          duration: duration
      },
      transitionEnd: {
        display: "none",
      },
    }
  }

  return {
    visible: {
      opacity: 1,
      display: display,
      transition: {
        duration: duration
      },
    },
    hidden,
  }
}