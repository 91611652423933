import React from 'react';

import CaptureProcessing from './components/pages/Capture/CaptureProcessing/CaptureProcessing';
import Home from './components/pages/Home/Home';
import Result from './components/pages/Result/Result';
import useWindowSize from './hooks/use-window-size';
import SingleCapture from './components/pages/Capture/SingleCapture/SingleCapture';
import { 
  Dict,
  IConnectionParams,
  ISingleCaptureParams
} from './models/capture';

import classes from './App.module.scss';

interface Props { 
  readonly connectionParams: IConnectionParams | null;
  readonly singleCaptureParams: ISingleCaptureParams | null;
  readonly isKiosk: boolean;
  readonly showPatienceLabel: boolean;
  readonly translationPath: string | null;
  readonly result: Dict | String;
  readonly showResult: boolean;
  readonly isRotatePassport: boolean;
  readonly startComponentHandler: (caseId: string, libName: string, extraDataAdditions: object, flowConfigName: string) => void;
  readonly captureCompleteHandler: (res: Dict) => void; // TODO::?? change dict to err interface?
  readonly captureErrorHandler: (err: Dict) => void; // TODO::?? change dict to err interface?
  readonly resultCompleteHandler: () => void;
}

const AppView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
   
  const size = useWindowSize();

  return props.connectionParams ? (
    <CaptureProcessing
      connectionParams={props.connectionParams}
      showPatienceLabel={props.showPatienceLabel}
      size={size}
      translationPath={props.translationPath}
      isKiosk={props.isKiosk}
      isRotatePassport={props.isRotatePassport}
      onComplete={props.captureCompleteHandler}
      onError={props.captureErrorHandler}
    />
  ) : props.singleCaptureParams ? (
      <SingleCapture
        singleCaptureParams={props.singleCaptureParams}
        size={size}
        onComplete={props.captureCompleteHandler}
        onError={props.captureErrorHandler}
      />
    ) : ( 
      <div
        className={classes['container']}
      >
        <Home
          startComponentHandler={props.startComponentHandler}
          size={size}
        />
        <Result
          result={props.result}
          showResult={props.showResult}
          completeButtonClickHandler={props.resultCompleteHandler}
          size={size}
        />
      </div>
    )
};

AppView.displayName = 'AppView';
AppView.defaultProps = {};

export default AppView;