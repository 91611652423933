/* eslint-disable react-hooks/exhaustive-deps */
import React, { CSSProperties, useEffect, useState } from 'react';

import { IRect, rectZero } from '../../../../../../models/capture';
import StopRecImgSrc from "../../../../../../assets/svg/stop_rec.svg"
import StopRecSpinnerImgSrc from "../../../../../../assets/gif/stop_rec_spinner.gif"

import RecordOverlayView from './RecordOverlay.view';

interface Props { 
  readonly targetRect: IRect;
  readonly isOTP: boolean;
  readonly showRecordOverlay: boolean;
  readonly codeArray: string[];
  readonly recordingNumbersFontFamily: string;
  readonly recordingButtonTitleFontFamily: string;
  readonly recordingButtonTitle: string;
  readonly maxRecordDuration: number;
  readonly finishRecordingHandler: (sendReportSuccess: () => void) => void;
}

const RecordOverlay: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  
  const [codeContainerRectState, setCodeContainerRectState] = useState<IRect>(rectZero);
  const [codeStyleState, setCodeStyleState] = useState<CSSProperties>({});
  const [labelStyleState, setLabelStyleState] = useState<CSSProperties>({});
  const [currentButtonImageState, setCurrentButtonImageState] = useState<string>(StopRecImgSrc);
  const [buttonRectState, setButtonRectState] = useState<IRect>(rectZero);
  const [isButtonDisableState, setIsButtonDisableState] = useState<boolean>(false);
  const [stopRecordTimeoutState, setStopRecordTimeoutState] = React.useState<any>();

  useEffect(() => {
    stopRecordTimeoutState && clearTimeout(stopRecordTimeoutState);

    if (props.showRecordOverlay) {
      setStopRecordTimeoutState(() => setTimeout(() => {
        finishRecordHandler();
      }, props.maxRecordDuration * 1000));
    }
  }, [props.showRecordOverlay]);

  useEffect(() => {
    setIsButtonDisableState(() => false);
    const img = new Image();
    img.src = StopRecImgSrc + "?a=" + Math.random();
    setCurrentButtonImageState(() => img.src)
  }, [props.isOTP]);

  useEffect(() => {
    if (props.targetRect.width > 0) {
      const numOfDiv = props.isOTP ? 4 : 1;
      const fontSizePercent = props.isOTP ? 0.5 : 0.25;
      const containerWidthPercent = props.isOTP ? 0.691 : 0.89;
      const containerWidth = props.targetRect.width * containerWidthPercent;
      const divMargin = containerWidth * 0.008;
      const containerHeight = ((containerWidth - (divMargin * 8)) / 4) * 1.186;

      setCodeContainerRectState(() => ({
        height: containerHeight,
        width: containerWidth,
        top: props.targetRect.top + props.targetRect.height - containerHeight - (props.targetRect.height * 0.08),
        left: props.targetRect.left + ((props.targetRect.width - containerWidth) / 2)
      }));

      setCodeStyleState(() => ({
        width: (containerWidth - (divMargin * (numOfDiv * 2))) / numOfDiv,
        margin: divMargin,
        fontSize: containerHeight * fontSizePercent,
        fontFamily: props.recordingNumbersFontFamily,
      }));

      const labelFromTop = Math.min(props.targetRect.width * 0.072, 27);
      const labelHeight = Math.min(props.targetRect.width * 0.05, 19);
      setLabelStyleState(() => ({
        top: props.targetRect.top + props.targetRect.height + labelFromTop,
        fontSize: Math.min(props.targetRect.width * 0.039, 15),
        height: labelHeight,
        width: props.targetRect.width,
        left: props.targetRect.left,
        fontFamily: props.recordingButtonTitleFontFamily,
      }));

      const ButtonSide = props.targetRect.width * 0.22;
      setButtonRectState(() => ({
        top: props.targetRect.top + props.targetRect.height + labelFromTop + labelHeight + Math.min(props.targetRect.width * 0.045, 17),
        left: props.targetRect.left + ((props.targetRect.width - ButtonSide) / 2),
        height: ButtonSide,
        width: ButtonSide,
      }));
    }
  }, [props.targetRect, props.isOTP, props.recordingNumbersFontFamily]);

  const finishRecordHandler = () => {
    stopRecordTimeoutState && clearTimeout(stopRecordTimeoutState);
    setIsButtonDisableState(() => true);
    const img = new Image();
    img.src = StopRecSpinnerImgSrc + "?a=" + Math.random();
    props.finishRecordingHandler(() => {
      setCurrentButtonImageState(img.src);
    });
  }

  return (
    <RecordOverlayView
      showRecordOverlay={props.showRecordOverlay}
      title={props.recordingButtonTitle}
      codeArray={props.codeArray}
      codeContainerRect={codeContainerRectState}
      codeStyle={codeStyleState}
      labelStyle={labelStyleState}
      currentButtonImage={currentButtonImageState}
      buttonRect={buttonRectState}
      isButtonDisable={isButtonDisableState}
      finishRecordClickHandler={finishRecordHandler}
      children={props.children}
      />
  );
};

RecordOverlay.displayName = 'RecordOverlay';
RecordOverlay.defaultProps = {};

export default React.memo(RecordOverlay);
