import React, { ReactNode } from "react";
import { motion } from 'framer-motion';

import { visibilityVariant } from '../../../../../models/capture';

import classes from './PaintedRectTarget.module.scss';

interface Props { 
  children?: ReactNode;
  readonly showPaintedRectTarget: boolean;
  readonly canvasRef?: React.Ref<HTMLCanvasElement>
}

const PaintedRectTargetView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => (
  <motion.canvas 
    className={classes['canvas']}
    ref={props.canvasRef}
    variants={visibilityVariant("block" ,0.25, true)}
    initial="visible"
    animate={props.showPaintedRectTarget ? "visible" : "hidden"}
  />
);

PaintedRectTargetView.displayName = 'PaintedRectTargetView';
PaintedRectTargetView.defaultProps = {};

export default PaintedRectTargetView;
