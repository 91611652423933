import React from 'react';

import ComponentsSelectionView from './ComponentsSelection.view';

interface Props { 
  readonly size: { width: number; height: number };
  readonly components: string[];
  readonly startComponentHandler: (component: string) => void;
}

const ComponentsSelection: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {

  const selectRef = React.useRef<HTMLSelectElement>(null);

  const selectionChangeHandler = (selection: string) => {
    props.startComponentHandler(selection);
    if (selectRef.current){
      selectRef.current!.value = "Select Document to Scan";
    }
  }

  return (
    <ComponentsSelectionView
      size={props.size}
      selectRef={selectRef}
      components={props.components}
      selectionChangeHandler={selectionChangeHandler}
      children={props.children}
      />
  );
};

ComponentsSelection.displayName = 'ComponentsSelection';
ComponentsSelection.defaultProps = {};

export default ComponentsSelection;
