import React, { useEffect, useState } from 'react';
import Webcam from 'react-webcam';

import { ISize } from '../../../../../hooks/use-window-size';
import { IRect } from '../../../../../models/capture';

import SingleCaptureWebcamView from './SingleCaptureWebcam.view';

interface Props { 
  readonly size: ISize;
  readonly webcamRef: React.RefObject<Webcam>
  readonly onUserMedia: (stream: MediaStream) => void;
  readonly onUserMediaError :(error: string | DOMException) => void;
}

const SingleCaptureWebcam: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  
  const [cameraPreviewRectState, setCameraPreviewRectState] = useState<IRect>({
    width:props.size.width,
    height:props.size.height,
    top: 0,
    left: 0,
  });

  useEffect(() => {
    const isLandscape = props.size.width > props.size.height;
      if (isLandscape && props.size.width > 1024 && props.size.height > 553) {
        setCameraPreviewRectState({
          width: 894,
          height: 503,
          top: 50,
          left: (props.size.width / 2) - 447,
        });
      } else {
        setCameraPreviewRectState({
          width:props.size.width,
          height:props.size.height,
          top: 0,
          left: 0,
        });
      }

  }, [props.size]);

  return (
    <SingleCaptureWebcamView
      cameraPreviewRect={cameraPreviewRectState}
      webcamRef={props.webcamRef}
      onUserMedia={props.onUserMedia}
      onUserMediaError={props.onUserMediaError}
      children={props.children}
      />
  );
};

SingleCaptureWebcam.displayName = 'SingleCaptureWebcam';
SingleCaptureWebcam.defaultProps = {};

export default SingleCaptureWebcam;
