import React, { CSSProperties, ReactNode } from 'react';
import { motion } from 'framer-motion';

import { visibilityVariant } from '../../../../../../models/capture';

import classes from './TargetLineAnimation.module.scss';

interface Props { 
  children?: ReactNode
  readonly style: CSSProperties;
  readonly show: boolean;
}

const TargetLineAnimationView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => (
  <motion.div
    className={classes['targetLine']}
    style={props.style}
    variants={visibilityVariant('block', 0.25, true)}
    animate={props.show ? "visible" : "hidden"}
    initial="hidden"
  />
);

TargetLineAnimationView.displayName = 'TargetLineAnimationView';
TargetLineAnimationView.defaultProps = {};

export default TargetLineAnimationView;
