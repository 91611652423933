import React, { ReactNode } from "react";

import CaptureProcessingWebcam from './CaptureProcessingWebcam/CaptureProcessingWebcam';
import CaptureProcessingOverlay from './CaptureProcessingOverlay/CaptureProcessingOverlay';
import {
    Dict,
    ICPalette,
    IImagesCustomizations,
    IRect,
    IStylesCustomizations,
    ITitles,
} from '../../../../models/capture';

import classes from './CaptureProcessing.module.scss';

interface Props {
  children?: ReactNode
  readonly cameraPreviewRect: IRect;
  readonly isConnect: boolean;
  readonly stream?: MediaStream;
  readonly needToRecordAudio: boolean;
  readonly needToRecordVideo: boolean;    
  readonly isLiveness: boolean;
  readonly cameraFacingMode?: string;
  readonly stageCode: number;
  readonly messageCode: number | null;
  readonly cPalette?: ICPalette;
  readonly isRotate90: boolean;
  readonly needNewFrame: boolean;
  readonly cardRatio: number;
  readonly styles: IStylesCustomizations;
  readonly titles: ITitles;
  readonly messages: Dict;
  readonly images: IImagesCustomizations;
  readonly stageOrder: number;
  readonly numOfStages: number;
  readonly stageData?: string;
  readonly maxRecordDuration: number;
  readonly showPatienceLabel: boolean;
  readonly gesturesIcons: string[];
  readonly gesturesResources: any;
  readonly gesturesTimeForIcon: number;
  readonly showX: boolean;
  readonly isUpload: boolean;
  readonly mimeType?: string | null;
  readonly isKiosk: boolean;
  readonly onUserMedia: (stream: MediaStream) => void;
  readonly onUserMediaError: (error: string | DOMException) => void;
  readonly sendFrame: (frame: Blob) => void;
  readonly sendVideoParts: (videoParts: Blob[]) => void;
  readonly emptyMessageCode: () => void;
  readonly finishRecordingHandler: (sendReportSuccess: () => void) => void;
  readonly backButtonClickHandler: () => void;
}

const CaptureProcessingView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => (
  <div
    className={classes['container']}
    style={{...props.cameraPreviewRect}}
  >
    <CaptureProcessingWebcam
      cameraPreviewRect={props.cameraPreviewRect}
      isConnect={props.isConnect}
      stream={props.stream}
      needToRecordAudio={props.needToRecordAudio}
      needToRecordVideo={props.needToRecordVideo}
      mirrored={props.isLiveness}
      cameraFacingMode={props.cameraFacingMode}
      stageCode={props.stageCode}
      cPalette={props.cPalette}
      isRotate90={props.isRotate90}
      needNewFrame={props.needNewFrame}
      mimeType={props.mimeType}
      isKioskLiveness={props.isKiosk && props.isLiveness}
      onUserMedia={props.onUserMedia}
      onUserMediaError={props.onUserMediaError}
      sendFrame={props.sendFrame}
      sendVideoParts={props.sendVideoParts}
    />
    <CaptureProcessingOverlay
      cameraPreviewRect={props.cameraPreviewRect}
      cardRatio={props.cardRatio}
      isConnectWithStream={props.isConnect && props.stream ? true : false}
      isLiveness={props.isLiveness}
      isRotate90={props.isRotate90}
      styles={props.styles}
      stageCode={props.stageCode}
      titles={props.titles}
      images={props.images}
      stageOrder={props.stageOrder}
      numOfStages={props.numOfStages}
      messages={props.messages}
      messageCode={props.messageCode}
      stageData={props.stageData}
      maxRecordDuration={props.maxRecordDuration}
      gesturesIcons={props.gesturesIcons}
      gesturesResources={props.gesturesResources}
      gesturesTimeForIcon={props.gesturesTimeForIcon}
      showPatienceLabel={props.showPatienceLabel}
      showX={props.showX}
      isUpload={props.isUpload}
      finishRecordingHandler={props.finishRecordingHandler}
      emptyMessageCode={props.emptyMessageCode}
      backButtonClickHandler={props.backButtonClickHandler}
    />
  </div>
);


CaptureProcessingView.displayName = 'CaptureProcessingView';
CaptureProcessingView.defaultProps = {};

export default CaptureProcessingView;
