import React, { CSSProperties, ReactNode } from 'react';
import { motion } from 'framer-motion';

import { visibilityVariant } from '../../../models/capture';
import { IResultHeader } from '../../../models/result';
import CCResultDataCell from '../../ui/CCResultDataCell/CCResultDataCell';
import StageResult from './StageResult/StageResult';

import classes from './Result.module.scss';

interface Props { 
  children?: ReactNode;
  readonly showResult: boolean;
  readonly headers: IResultHeader[];
  readonly stages: any[]; // any?
  readonly listStyle: CSSProperties;
  readonly buttonStyle: CSSProperties;
  readonly completeButtonClickHandler: () => void;
}

const ResultView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => (
  <motion.div
    className={classes['container']}
    variants={visibilityVariant("flex" ,0.25, true)}
    animate={props.showResult ? "visible" : "hidden"}
    initial="hidden"
  >
    <h1 className={classes['container__title']}> Results </h1>
    <div 
      className={classes['container__list']} 
      style={props.listStyle}
    >
      {props.headers.map((header, index) => {
        return(
          <CCResultDataCell 
            title = {header.title}
            key={index}
            message = {header.message}/>
        )
      })}
      {props.stages.map((stage, index) => {
        return(
          <StageResult
            payload={stage.payload}
            title={stage.stage.type}
            key={index}
          />
        )
      })}
    </div>
    <button
      className={classes['container__button']}
      style={props.buttonStyle}
      onClick={props.completeButtonClickHandler}
    >Complete</button>
  </motion.div>
);

ResultView.displayName = 'ResultView';
ResultView.defaultProps = {};

export default ResultView;
