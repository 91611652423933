import React, { ReactNode, useMemo } from "react";
import { motion } from "framer-motion";
import Webcam from "react-webcam";
import { isMobile } from 'react-device-detect';

import { 
  cameraSize,
  visibilityVariant,
  IRect,
} from '../../../../../models/capture';

import classes from './CaptureProcessingWebcam.module.scss';

interface Props { 
  children?: ReactNode;
  readonly isConnect: boolean;
  readonly stream?: MediaStream;
  readonly cameraPreviewRect: IRect;
  readonly webcamRef?: React.Ref<Webcam>;
  readonly audioConstraints: boolean;
  readonly mirrored: boolean;
  readonly isKioskLiveness: boolean;
  readonly cameraFacingMode?: string;
  readonly needToRecordVideo: boolean;
  readonly onUserMedia: (stream: MediaStream) => void;
  readonly onUserMediaError :(error: string | DOMException) => void;
}

const CaptureProcessingWebcamView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {

  const transformContainerClasses = `${classes['container']} ${classes['container--transform']}`;

  const videoConstraints = useMemo(() => {
    let tempConstraints: any = {
      tag: "SNWebcam",
      width: props.isKioskLiveness ? { min: 1280, ideal: cameraSize.kioskLivenessBigSize }: cameraSize.bigSize,
      height: props.isKioskLiveness ? { min: 720, ideal: cameraSize.kioskLivenessSmallSize }:  cameraSize.smallSize,
      facingMode: props.cameraFacingMode ? props.cameraFacingMode : props.mirrored ? "face" : "environment", 
    }

    if (props.needToRecordVideo){
      tempConstraints.frameRate = {min: 25}
    } 

    return tempConstraints;

  }, [props.cameraFacingMode, props.isKioskLiveness, props.mirrored, props.needToRecordVideo]);

  return props.isConnect ? (
    <motion.div
      variants={visibilityVariant("block", 0.75, false)}
      animate={(props.stream) ? "visible" : "hidden"}
      initial="hidden"
    >
      <Webcam
        className={(!isMobile || (videoConstraints.facingMode === "face" && !props.mirrored )) ? transformContainerClasses : classes['container']}
        style={{
          top: props.cameraPreviewRect.top,
          left: props.cameraPreviewRect.left,
        }}
        height={props.cameraPreviewRect.height}
        width={props.cameraPreviewRect.width}
        ref={props.webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
        audioConstraints={props.audioConstraints}
        mirrored={props.mirrored}
        onUserMedia={props.onUserMedia}
        onUserMediaError={props.onUserMediaError}
        audio={true}
        muted={true}
      />
    </motion.div>
  ) : (
    null
  );
};

CaptureProcessingWebcamView.displayName = 'CaptureProcessingWebcamView';
CaptureProcessingWebcamView.defaultProps = {};

export default CaptureProcessingWebcamView;
