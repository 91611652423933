import React, { ReactNode } from "react";

import classes from './ComponentsSelection.module.scss';

interface Props { 
  children?: ReactNode;
  readonly size: { width: number; height: number };
  readonly selectRef?: React.LegacyRef<HTMLSelectElement>;
  readonly components: string[];
  readonly selectionChangeHandler: (selection: string) => void;
}

const ComponentsSelectionView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => (
  <select
    className={classes['container']}
    ref={props.selectRef}
    onChange={(e) => props.selectionChangeHandler(e.target.value)}
    style={{
      borderRadius: props.size.height * 0.015,
      marginTop: props.size.height * 0.157,
      height: props.size.height * 0.501 + 20 > props.size.width ? (props.size.width - 20) * 0.175 : props.size.height * 0.088,
      width: props.size.height * 0.501 + 20 > props.size.width ? props.size.width - 20 : props.size.height * 0.501
    }}
  >
    <option
      className={classes['container__option']}
      value='Select Document to Scan'
    >Select Document to Scan:</option>

    {props.components.map((component) => (
      <option
        className={classes['container__option']}
        key={component}
        value={component}
      >{component}</option>
    ))}
  </select>
);

ComponentsSelectionView.displayName = 'ComponentsSelectionView';
ComponentsSelectionView.defaultProps = {};

export default ComponentsSelectionView;
