export class ScanovateError extends Error {
  code: number;

  constructor (name: string, message: string, code: number) {
    super();

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ScanovateError.prototype);

    this.name = name;
    this.message = message;
    this.code = code;
  }
}

export class SessionAbandoned extends ScanovateError {
  constructor() {
    super(
      'SessionAbandoned',
      'Session visibility change to hidden',
      1104
    );
  }
}

export class UserCanceled extends ScanovateError {
  constructor() {
    super(
      'UserCanceled',
      'User pressed cancel',
      1105
    );
  }
}

export class GetUserMediaNotImplemented extends ScanovateError {
  constructor() {
    super(
      'getUserMediaNotImplemented',
      'getUserMedia is not implemented in this browser'
      ,1106
    );
  }
}

export class CameraAccessAttemptWithoutSSL extends ScanovateError {
  constructor() {
    super(
      'CameraAccessAttemptWithoutSSL',
      'Browsers forbid camera access in a non SSL website'
      ,1107
    );
  }
}

export class CameraNoRelevantDeviceFound extends ScanovateError {
  constructor() {
    super(
      'CameraNoRelevantDeviceFound',
      'No relevant device found during enumeration',
      1108
    );
  }
}

export class CouldNotGetMediaStreamError extends ScanovateError {
  constructor(name: string, message: string) {
    super(
      name,
      message,
      1109
    );
  }
}

export class RequestTimedOutError extends ScanovateError {
  constructor() {
    super(
      'RequestTimedOutError',
      'Request timed out',
      1110
    );
  }
}

export class RequestFailedError extends ScanovateError {
  constructor() {
    super(
      'RequestFailedError',
      'Request failed',
      1111
    );
  }
}

export class RequestStatusError extends ScanovateError {
  constructor() {
    super(
      'RequestStatusError',
      'Request status error',
      1112
    );
  }
}

export class ServerError extends ScanovateError {
  constructor(message: string, code: number) {
    super(
      'ServerError',
      message,
      code
    );
  }
}