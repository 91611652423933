import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import CCSvg from '../../../../ui/CCSvg/CCSvg';
import SpinnerImgSrc from '../../../../../assets/svg/spinner.svg'
import PaintedRectTarget from '../../CommonOverlayComponents/PaintedRectTarget/PaintedRectTarget';
import TargetLineAnimation from './TargetLineAnimation/TargetLineAnimation';
import CaptureInstruction from '../../CommonOverlayComponents/CaptureInstruction/CaptureInstruction';
import RecordOverlay from './RecordOverlay/RecordOverlay';
import GestureOverlay from './GestureOverlay/GestureOverlay';
import CaptureToast from '../../CommonOverlayComponents/CaptureToast/CaptureToast';
import {
  visibilityVariant,
  IRect,
  IStylesCustomizations,
} from '../../../../../models/capture';

import classes from './CaptureProcessingOverlay.module.scss';

interface Props {
  children?: ReactNode;
  readonly cameraPreviewRect: IRect;
  readonly styles: IStylesCustomizations;
  readonly instructionFontSize: number;
  readonly toastFontSize: string | number;
  readonly isConnectWithStream: boolean;
  readonly showPaintedRectTarget: boolean;
  readonly paintedRectTarget: IRect;
  readonly showLineAnimation: boolean;
  readonly targetLineRect: IRect;
  readonly instructionTop: number;
  readonly toastTop: number;
  readonly gesturesInstructionTop: number;
  readonly instructionLabelText: string;
  readonly toastText: string;
  readonly progressLabel: string;
  readonly checkmarkImgSrc: string;
  readonly showToast: boolean;
  readonly showCheckmark: boolean;
  readonly currentStageImgSrc?: string;
  readonly stageImgStyle: CSSProperties;
  readonly showStageImg: boolean;
  readonly isOTP: boolean;
  readonly showRecordOverlay: boolean;
  readonly showGestureOverlay: boolean;
  readonly codeArray: string[];
  readonly recordingButtonTitle: string;
  readonly maxRecordDuration: number;
  readonly gesturesResources: any;
  readonly gesturesIcons:string[]
  readonly gesturesTimeForIcon: number;
  readonly showSpinner: boolean;
  readonly showX: boolean;
  readonly isUpload: boolean;
  readonly isRotate90: boolean;
  readonly finishRecordingHandler: (sendReportSuccess: () => void) => void;
  readonly backButtonClickHandler: () => void;
  readonly finishGestureHandler: () => void;
}

const CaptureProcessingOverlayView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  
  const [labelStyleState, setLabelStyleState] = useState<React.CSSProperties>({});

  useEffect(() => {

    let labelStyle : React.CSSProperties= {
      color: props.styles.instructionLabelColor,
      fontFamily: props.styles.instructionLabelFontFamily,
      backgroundColor: props.styles.instructionLabelBg,
      borderRadius: props.styles.instructionLabelBorderRadius,
      fontSize: props.instructionFontSize,
    }

    if (!props.isRotate90) labelStyle.width = props.paintedRectTarget.width;

    setLabelStyleState(labelStyle);
  }, [props.instructionFontSize, props.isRotate90, props.paintedRectTarget.width, props.styles.instructionLabelBg, props.styles.instructionLabelBorderRadius, props.styles.instructionLabelColor, props.styles.instructionLabelFontFamily]);

  return (
    <div 
      className={classes['container']}
      style={{...props.cameraPreviewRect}}
    >
      <motion.div
        className={classes['motionContainer']}
        variants={visibilityVariant("block", 0.75, false)}
        animate={props.isConnectWithStream ? "visible" : "hidden"}
        initial="hidden"
      >
        <h1
          className={classes['motionContainer__progressLabel']}
          style={{
            color: props.styles.progressLabelColor,
            opacity: props.styles.progressLabelOpacity,
            fontFamily: props.styles.progressLabelFontFamily,
          }}
        >{props.progressLabel}</h1>
        <RecordOverlay
          targetRect={props.paintedRectTarget}
          isOTP={props.isOTP}
          showRecordOverlay={props.showRecordOverlay}
          codeArray={props.codeArray}
          recordingNumbersFontFamily={props.styles.recordingNumbersFontFamily}
          recordingButtonTitleFontFamily={props.styles.recordingButtonTitleFontFamily}
          recordingButtonTitle={props.recordingButtonTitle}
          maxRecordDuration={props.maxRecordDuration}
          finishRecordingHandler={props.finishRecordingHandler}
        />
        <GestureOverlay
          showGestureOverlay={props.showGestureOverlay}
          resources={props.gesturesResources}
          gestures={props.gesturesIcons}
          timeForIcon={props.gesturesTimeForIcon}
          gestureIconPassColor={props.styles.gestureIconPassColor}
          finishRecordingHandler={props.finishRecordingHandler}
          finishGestureHandler={props.finishGestureHandler}
        />
        <TargetLineAnimation
          style={{...props.targetLineRect, backgroundColor: props.styles.scanLineColor}}
          show={props.showLineAnimation}
        />
        <PaintedRectTarget
          showPaintedRectTarget={props.showPaintedRectTarget}
          targetRect={props.paintedRectTarget}
        />
        <CaptureInstruction
          instructionTop={props.showGestureOverlay ? props.gesturesInstructionTop : props.instructionTop}
          labelStyle={labelStyleState}
          instructionLabelText={props.instructionLabelText}
        />
        <CaptureToast
          showToast={props.showToast}
          toastText={props.toastText}
          toastTop={props.toastTop}
          toastStyle={{
            fontSize: props.toastFontSize,
            fontFamily: props.styles.instructionLabelFontFamily,
          }}
        />
        <motion.img
          className={classes['container__checkmark']}
          style={{
              left: props.paintedRectTarget.left + (props.paintedRectTarget.width / 2) - 27,
              top: props.paintedRectTarget.top + (props.paintedRectTarget.height / 2) - 27,
          }}
          src={props.checkmarkImgSrc}
          alt=""
          variants={visibilityVariant("inline", 0.25, true)}
          animate={props.showCheckmark ? "visible" : "hidden"}
          initial="hidden"
        />
        <motion.img
          className={classes['container__stageImg']}
          style={props.stageImgStyle}
          src={props.currentStageImgSrc}
          alt=""
          variants={visibilityVariant("inline", 0.25, true)}
          animate={props.showStageImg ? "visible" : "hidden"}
          initial="hidden"
        />
      </motion.div>
      <motion.img
        className={classes['container__spinner']}
        src={SpinnerImgSrc}
        alt=""
        variants={visibilityVariant("inline", 0.25, true)}
        animate={props.showSpinner || props.isUpload ? "visible" : "hidden"} 
        initial="visible"
      />
      {props.showX && <CCSvg
        className={classes['container__backIcon']}
        name="backX"
        onClick={props.backButtonClickHandler}
      />}
    </div>
  );
}

CaptureProcessingOverlayView.displayName = 'CaptureProcessingOverlayView';
CaptureProcessingOverlayView.defaultProps = {};

export default CaptureProcessingOverlayView;
