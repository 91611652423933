import React, { ReactNode } from "react";

import SettingsDialog from './SettingsDialog/SettingsDialog';
import LogoImgSrc from './../../../assets/images/scanovate_logo.png';
import CardImgSrc from './../../../assets/images/card_scanning.png';
import ComponentsSelection from './ComponentsSelection/ComponentsSelection';
import { ISize } from '../../../hooks/use-window-size';

import classes from './Home.module.scss';

interface Props { 
  children?: ReactNode
  readonly size: ISize;
  readonly flowConfigName: string;
  readonly components: string[];
  readonly submitSettingsHandler: (newConfigName: string) => void;
  readonly startComponentHandler: (component: string) => void;
}

const HomeView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => (
  <main 
    className={classes['container']}
    style={{
      width: props.size.width, 
      height: props.size.height,
    }}
  >
    <SettingsDialog
      flowConfigName={props.flowConfigName}
      submitSettingsHandler={props.submitSettingsHandler}
    />
    <img 
      src={LogoImgSrc}
      alt='Logo'
      style={{
        height: props.size.height * 0.064,
        width: props.size.height * 0.26,
      }}
    />
    <img 
      src={CardImgSrc}
      alt='Card'
      style={{
        marginTop: props.size.height * 0.101,
        height: props.size.height * 0.222,
        width: props.size.height * 0.381,
      }}
    />
    <h1 
      className={classes['container__text']}
      style={{
        marginTop: props.size.height * 0.145,
        height: props.size.height * 0.1,
        fontSize: props.size.height * 0.025,
        letterSpacing: - props.size.height * 0.0005,
      }}
    >Welcome! <br/> As soon as you are ready, click the button and <br/> select the OCR you would like to preform</h1>
    <ComponentsSelection
      size={props.size}
      components={props.components}
      startComponentHandler={props.startComponentHandler}
    />
  </main>
);

HomeView.displayName = 'HomeView';
HomeView.defaultProps = {};

export default HomeView;
