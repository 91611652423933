import React, { CSSProperties, ReactNode } from 'react';
import { motion } from 'framer-motion';

import {
  IRect,
  visibilityVariant
} from '../../../../../../models/capture';

import classes from './RecordOverlay.module.scss';

interface Props { 
  children?: ReactNode;
  readonly showRecordOverlay: boolean;
  readonly codeContainerRect: IRect;
  readonly codeArray: string[];
  readonly codeStyle: CSSProperties;
  readonly labelStyle: CSSProperties;
  readonly title: string;
  readonly currentButtonImage: string;
  readonly buttonRect: IRect;
  readonly isButtonDisable: boolean;
  readonly finishRecordClickHandler: () => void;
}

const RecordOverlayView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => (
  <motion.div
    className={classes['container']}
    variants={visibilityVariant("block" ,0.25, true)}
    initial="hidden"
    animate={props.showRecordOverlay ? "visible" : "hidden"}
  >
    <div
      className={classes['codeContainer']}
      style={{...props.codeContainerRect}}
    >
      {props.codeArray.map((code, index) => {
        return (
          <div
            className={classes['codeContainer__code']}
            style={props.codeStyle}
            key={index}
          >{code}</div>
        )
      })}  
    </div>
    <h1
      className={classes['container__label']}
      style={props.labelStyle}
    >{props.title}</h1>
    <img
      className={classes['container__button']}
      src={props.currentButtonImage}
      alt=""
      style={{...props.buttonRect}}
    />
    <div
      className={classes['container__button']}
      style={{
        ...props.buttonRect,
        zIndex:3
      }}
      onClick={props.isButtonDisable ? () => {} : () => props.finishRecordClickHandler()}
    />
  </motion.div>
);

RecordOverlayView.displayName = 'RecordOverlayView';
RecordOverlayView.defaultProps = {};

export default RecordOverlayView;
