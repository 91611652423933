/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { 
  defaultPaintedRectTargetStyles,
  IRect,
} from '../../../../../models/capture';

import PaintedRectTargetView from './PaintedRectTarget.view';

interface Props {
  readonly showPaintedRectTarget: boolean;
  readonly targetRect:IRect;
}

const PaintedRectTarget: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);

  // Draw new rect when changed
  useEffect(() => {
    if (canvasRef.current) {
      const canvasEle = canvasRef.current;
      canvasEle.width = canvasEle.clientWidth;
      canvasEle.height = canvasEle.clientHeight;
      const ctx = canvasEle.getContext("2d");
      ctx && drawRect(ctx);
    }
  }, [props.targetRect]);

  const drawRect = (ctx: CanvasRenderingContext2D) => {
    if (canvasRef.current && props.targetRect.width > 0) {
      const canvasEle = canvasRef.current;
      ctx.clearRect(0, 0, canvasEle.width, canvasEle.height);

      ctx.fillStyle = "rgba(0,0,0,0.6)";
      ctx.fillRect(0, 0, canvasEle.width, canvasEle.height);
      ctx.strokeStyle = defaultPaintedRectTargetStyles.borderColor;
      ctx.lineWidth = defaultPaintedRectTargetStyles.borderWidth;
      
      ctx.beginPath();
      ctx.moveTo(props.targetRect.left + defaultPaintedRectTargetStyles.radius.tl, props.targetRect.top);
      ctx.lineTo(props.targetRect.left + props.targetRect.width - defaultPaintedRectTargetStyles.radius.tr, props.targetRect.top);
      ctx.quadraticCurveTo(props.targetRect.left + props.targetRect.width, props.targetRect.top, props.targetRect.left + props.targetRect.width, props.targetRect.top + defaultPaintedRectTargetStyles.radius.tr);
      ctx.lineTo(props.targetRect.left + props.targetRect.width, props.targetRect.top + props.targetRect.height - defaultPaintedRectTargetStyles.radius.br);
      ctx.quadraticCurveTo(props.targetRect.left + props.targetRect.width, props.targetRect.top + props.targetRect.height, props.targetRect.left + props.targetRect.width - defaultPaintedRectTargetStyles.radius.br, props.targetRect.top + props.targetRect.height);
      ctx.lineTo(props.targetRect.left + defaultPaintedRectTargetStyles.radius.bl, props.targetRect.top + props.targetRect.height);
      ctx.quadraticCurveTo(props.targetRect.left, props.targetRect.top + props.targetRect.height, props.targetRect.left, props.targetRect.top + props.targetRect.height - defaultPaintedRectTargetStyles.radius.bl);
      ctx.lineTo(props.targetRect.left, props.targetRect.top + defaultPaintedRectTargetStyles.radius.tl);
      ctx.quadraticCurveTo(props.targetRect.left, props.targetRect.top, props.targetRect.left + defaultPaintedRectTargetStyles.radius.tl, props.targetRect.top);
      ctx.closePath();

      ctx.stroke();
      ctx.globalCompositeOperation = "destination-out";
      ctx.fill();
    }
  }

  return (
    <PaintedRectTargetView
      canvasRef={canvasRef}
      showPaintedRectTarget={props.showPaintedRectTarget}
      children={props.children}
      />
  );
};

PaintedRectTarget.displayName = 'PaintedRectTarget';
PaintedRectTarget.defaultProps = {};

export default React.memo(PaintedRectTarget);
