import React, { ReactNode } from "react";
import {motion} from 'framer-motion';

import CCGestureBarIcon from '../../../../../ui/CCGestureBarIcon/CCGestureBarIcon';
import {GestureBarIconState} from '../../../../../ui/CCGestureSvg/CCGestureSvg';
import {visibilityVariant} from '../../../../../../models/capture';

import classes from './GestureOverlay.module.scss';

interface Props {
  children?: ReactNode;
  readonly showGestureOverlay: boolean;
  readonly gestures: string[];
  readonly resources: any;
  readonly currentIconIndex: number;
  readonly currentCounter: number | string;
  readonly gestureIconPassColor: string;
  readonly evenMaskImage: string;
  readonly oddMaskImage: string;
  readonly evenMaskLineImage: string;
  readonly oddMaskLineImage: string;
}

const GestureOverlayView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {

  const noMaskContainerClasses = `${classes['maskContainer']} ${classes['maskContainer--noMask']}`;
  const gesturesKeys = props.gestures ? props.gestures : [];
  const iconNames = [];
  if (props.resources) {
    for (let i = 0; i < gesturesKeys.length; i++) {
      iconNames.push(props.resources[gesturesKeys[i]].gesturesIcons)
    }
  }

  return (
    <motion.div
      className={classes['container']}
      variants={visibilityVariant("flex", 0.25, true)}
      initial="hidden"
      animate={props.showGestureOverlay ? "visible" : "hidden"}
    >
      {props.currentIconIndex < gesturesKeys.length ?
        <div className={classes['maskContainer']}>
          <div className={classes['maskContainer__maskHeder']}/>
          <div
            className={classes['maskContainer__mask']}
            style={{
              opacity: props.currentIconIndex % 2 === 0 ? 1 : 0,
              maskImage: props.evenMaskImage,
              WebkitMaskImage: props.evenMaskImage,
            }}
          />
          <div
            className={classes['maskContainer__mask']}
            style={{
              opacity: props.currentIconIndex % 2 === 0 ? 0 : 1,
              maskImage: props.oddMaskImage,
              WebkitMaskImage: props.oddMaskImage,
            }}
          />
          <img
            className={classes['maskContainer__maskBorder']}
            src={props.evenMaskLineImage}
            alt=""
            style={{opacity: props.currentIconIndex % 2 === 0 ? 1 : 0}}
          />
          <img
            className={classes['maskContainer__maskBorder']}
            src={props.oddMaskLineImage}
            alt=""
            style={{opacity: props.currentIconIndex % 2 === 0 ? 0 : 1}}
          />
        </div> : <div className={noMaskContainerClasses}/>
      }
      <div className={classes['container__progressBar']}>
        {iconNames.map((iconName, index) => {
          let state: GestureBarIconState = GestureBarIconState.Future;
          if (index === props.currentIconIndex) state = GestureBarIconState.Current;
          else if (index < props.currentIconIndex) state = GestureBarIconState.Past;
          else if (index > props.currentIconIndex) state = GestureBarIconState.Future;

          return (
            <CCGestureBarIcon
              key={index}
              iconName={iconName}
              state={state}
              gestureIconPassColor={props.gestureIconPassColor}
              counter={state === GestureBarIconState.Current ? props.currentCounter : undefined}
            />
          )
        })}
      </div>
    </motion.div>
  );
};

GestureOverlayView.displayName = 'GestureOverlayView';
GestureOverlayView.defaultProps = {};

export default GestureOverlayView;
