import axios from 'axios';
import { RequestFailedError, RequestStatusError, RequestTimedOutError } from '../../helpers/customErrors';
import AudioRecorder from './audio/RecorderService'

const FormData = require('form-data');
const debug = require('debug');
const { v4: uuidv4 } = require('uuid');

function removeExtraSlashesFromURL(url) {
  return url.replace(/([^:]\/)\/+/g, '$1');
}

function createSubProcessLogger() {
  const subProcessRegularString = 'recorder-service';
  const errorLevel = debug(`${subProcessRegularString}:error`);
  const debugLevel = debug(`${subProcessRegularString}:debug`);
  const infoLevel = debug(`${subProcessRegularString}:info`);
  const sillyLevel = debug(`${subProcessRegularString}:silly`);
  const warnLevel = debug(`${subProcessRegularString}:warn`);

  debugLevel.log = infoLevel.log = sillyLevel.log = warnLevel.log = errorLevel.log = console.log.bind(console);

  return {
    error: errorLevel,
    debug: debugLevel,
    info: infoLevel,
    silly: sillyLevel,
    warn: warnLevel,
  };
}

const possibleWebhookStates = {
  complete: 'complete',
  incomplete: 'incomplete',
  failed: 'failed',
  irrelevant: 'irrelevant',
};

function isNullUndefinedEmptyString(varToCheck) {
  return (varToCheck === null || varToCheck === undefined || varToCheck === '');
}

class RequestBuilder {
  constructor() {
    this.dataToSend = {};
    this.extraFields = {};
    this.timeout = 5000;
  }

  setTimeout(timeout) {
    this.timeout = timeout;
    return this;
  }

  setExtraFields(extraFields) {
    this.extraFields = extraFields;
    return this;
  }

  setDataToSend(dataToSend) {
    this.dataToSend = dataToSend;
    return this;
  }
}

export class ConnectionManager {
  /**
   * ConnectionManager constructor.
   *
   * @param {string | undefined} url - Base server URL, e.g. <protocol>://<domain>
   * @param {Object | undefined} extraData - Object holding various extra data on requests
   * @param {string | undefined} extraData.caseId - Case ID to attach to initial connection request
   * @param {string | undefined} extraData.clientId - Client ID to attach to initial connection request
   * @param {string | undefined} [extraData.clientTranslationFileName] - Name of file holding translations
   * @param {string | undefined} [extraData.devMode] - Whether to initiate developer mode on server during initial connection request
   * @param {string | undefined} [extraData.flowConfigName] - Name of requested config
   * @param {string | undefined} extraData.libraryName - Library name of which to use
   * @param {Object | undefined} [extraData.stagesConfiguration] - Stages config object
   * @param {Object | undefined} [extraData.stagesConfiguration.otp] - OTP config
   * @param {String | undefined} [extraData.stagesConfiguration.otp.number] - String to be sent to server
   * @param {String | undefined} [extraData.sttLanguage] - Language to use for stt, format XX-yy
   * @param {string | undefined} [extraData.token] - Token to use during initial connection
   * @param {string | undefined} [extraData.webhookInjectionParams] - Mapper object to be used during webhook sending for parameter injection
   * @param {Object | undefined} [relativeUrlsObj] - Object holding various paths on server
   * @param {string | undefined} [relativeUrlsObj.connectionUrl] - Relative URL to use during initial connection
   * @param {string | undefined} [relativeUrlsObj.postConnectionUrl] - Relative URL to use after initial connection
   * @api public
   */
  constructor(url, extraData, relativeUrlsObj, flowID) {
    if (!url && !extraData && !relativeUrlsObj) {
      return;
    }
    this.serverBaseUrl = url;
    this.caseId = extraData && extraData.caseId;
    this.clientId = extraData && extraData.clientId;
    this.devMode = extraData && extraData.devMode;
    this.webhookInjectionParams = extraData && extraData.webhookInjectionParams;
    this.stagesConfigObj = extraData && extraData.stagesConfiguration;
    this.audioRecorder = new AudioRecorder();
    if (isNullUndefinedEmptyString(this.caseId)) {
      throw new Error('extraData.caseId not supplied');
    }
    this.externalToken = extraData && extraData.token;
    this.libraryName = extraData && extraData.libraryName;
    if (isNullUndefinedEmptyString(this.libraryName)) {
      throw new Error('extraData.libraryName not supplied');
    }
    this.sessionUUID = uuidv4();
    this.flowUUID = flowID ? flowID : uuidv4();
    this.flowConfigName = extraData && extraData.flowConfigName;
    this.sttLanguage = extraData && extraData.sttLanguage;
    this.clientTranslationFileName = extraData && extraData.clientTranslationFileName;
    this.initialConnectionUrl = (relativeUrlsObj && relativeUrlsObj.connectionUrl) || '/communication/client_init';
    this.postInitialConnectionUrl = (relativeUrlsObj && relativeUrlsObj.postConnectionUrl) || '/communication/client_request';
    const debugLoggerInstance = createSubProcessLogger();
    this.logger = {
      debug: (debugLoggerInstance.debug),
      silly: (debugLoggerInstance.silly),
      error: (debugLoggerInstance.error),
      info: (debugLoggerInstance.info),
      warn: (debugLoggerInstance.warn)
    };
    this.logSocketResponseFn = (logPrefix, socketMessage) => {
      this.logger.debug(`Socket ${logPrefix}: ${socketMessage && typeof socketMessage === 'string' ? socketMessage : JSON.stringify(socketMessage)}`);
    };
    this._sendAudioToServerIfPossible = this._sendAudioToServerIfPossible.bind(this);
    this.stopAudioRecording = this.stopAudioRecording.bind(this);
  }

  /**
   *
   * @param {function} cb - Callback which will be called on error and on success
   * @param {Object} [config] - Object holding various extra data on requests
   * @param {number} [config.timeout] - Maximum allowed request time in milliseconds
   * @param {Object} stream - Stream object from which to take audio tracks
   * @api public
   */
  startAudioRecorder(cb, config, stream) {
    this.audioRecorder.once('ondataavailable', () => {
      this._sendAudioToServerIfPossible(cb, config);
    });
    this.audioRecorder.startRecording(stream);
  }

  _sendAudioToServerIfPossible(clientCb, config) {
    let audioBlob = this.audioRecorder.getBlobOfSomeChunks();
    if (window.getOutsideInjectedAudio) {
      audioBlob = window.getOutsideInjectedAudio();
    }
    const audioCb = (resOrErr) => {
      const isServiceStopped = this.audioRecorder.state === AudioRecorder.states.inactive;
      if (resOrErr instanceof Error) {
        this.stopAudioRecording();
        this.audioRecorder.stopRecording();
      } else {
        if (isServiceStopped === false) {
          this._sendAudioToServerIfPossible(clientCb, config);
        }
      }
      if (isServiceStopped === false) {
        clientCb(resOrErr);
      }
    };
    if (audioBlob && audioBlob.blob && audioBlob.blob.size > 0) {
      const configToSend = config || {};
      configToSend.timestamp = audioBlob.timestamp;
      this.sendAudioChunk(audioCb, config, audioBlob.blob);
    } else {
      if (this.audioRecorder.state !== AudioRecorder.states.inactive) {
        setTimeout(() => {
          this._sendAudioToServerIfPossible(clientCb, config);
        }, 250);
      }
    }
  }

  stopAudioRecording() {
    this.audioRecorder.stopRecording();
  }

  /**
   * Used in order to establish initial connection
   * @param {Object} [config] - Object holding various extra data on requests
   * @param {number} [config.timeout] - Maximum allowed request time in milliseconds
   *
   * @api public
   */
  async connect(config) {
    return new Promise(async (res, rej) => {
      const objToSend = {
        clientTranslationFileName: this.clientTranslationFileName,
        caseId: this.caseId,
        clientId: this.clientId,
        libraryName: this.libraryName,
        devMode: !!this.devMode
      };

      this.flowConfigName && (objToSend.flowConfigName = this.flowConfigName);
      this.sttLanguage && (objToSend.sttLanguage = this.sttLanguage);
      this.webhookInjectionParams && (objToSend.webhookInjectionParams = this.webhookInjectionParams);
      this.stagesConfigObj && (objToSend.stagesConfig = this.stagesConfigObj);

      const headers = {
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Flow-ID' : this.flowUUID,
        'X-Session-ID': this.sessionUUID,
        'X-Request-ID': uuidv4(),
      }
      if (this.externalToken) headers['Authorization'] = `Bearer ${this.externalToken}`;
      let timeout = 7500;
      if (config && config.timeout) timeout = config.timeout;

      await axios.post(removeExtraSlashesFromURL(`${this.serverBaseUrl}/${this.initialConnectionUrl}`),
        objToSend,
        {
          headers: headers,
          timeout: timeout,
          withCredentials: true,
        }
      ).then(axiosResponse => {
        const status = axiosResponse.status;
        this.logger.debug(`Request succeeded with status: ${status}`);
        
        res(axiosResponse.data);
      }).catch(error => {
        console.log({error});
        this.logger.error(`Request had error: ${(error && error.message) || error}`);
        if (error.response) {
          rej(new RequestStatusError(error.response.status,error.response.message));
        } else if (error.code === 'ECONNABORTED') {
           rej(new RequestTimedOutError())
        } else {
          rej(new RequestFailedError());
        }
      });
    })
  }

  /**
   * Used in order to send an image for analysis
   *
   * @param {function} cb - Callback which will be called on error and on success
   * @param {Blob} audioChunk - Audio chunk to be sent to server
   * @param {Object} [config] - Object holding various extra data on requests
   * @param {number} [config.doNotProcessFrame] - Whether no not analyze frame
   * @param {number} [config.timeout] - Maximum allowed request time in milliseconds
   * @param {number} [config.timestamp] - Timestamp to set on blob
   * @api public
   */
  sendAudioChunk(cb, config, audioChunk) {
    const msgType = 'audio_chunk_request';
    this.logger.debug(`scanovate connection about to emit '${msgType}'`);
    let doNotProcessFrame = false;
    if (config && (config.doNotProcessFrame === true || config.doNotProcessFrame === false)) {
      doNotProcessFrame = config.doNotProcessFrame;
    } else {
      doNotProcessFrame = true;
    }
    const request = new RequestBuilder()
      .setDataToSend({ frame: audioChunk })
      .setExtraFields({
        should_process_frame: !doNotProcessFrame,
        message_type: msgType,
        timestamp: (config && config.timestamp) || '' + Date.now()
      });

    request.setTimeout((config && config.timeout) || 10000);
    this._sendRequestToServer(request).then((responseMsg) => {
      this.logSocketResponseFn(msgType, responseMsg);
      cb && cb(responseMsg);
    }, (err) => {
      cb && cb(err);
    });
  }

  /**
   * Used in order to inquire if webhook is done
   *
   * @param {function} cb - Callback which will be called on error and on success
   * @param {Object} [config] - Object holding various extra data on requests
   * @param {number} [config.timeout] - Maximum allowed request time in milliseconds
   * @param {number} [config.timestamp] - Timestamp to set on blob
   * @api public
   */
  _inquireIsWebhookDone(cb, config) {
    const msgType = 'webhook_done_check';
    this.logger.debug(`scanovate connection about to emit '${msgType}'`);
    const request = new RequestBuilder()
      .setExtraFields({
        message_type: msgType,
        timestamp: (config && config.timestamp) || '' + Date.now()
      });

    request.setTimeout((config && config.timeout) || 10000);
    this._sendRequestToServer(request).then((responseMsg) => {
      this.logSocketResponseFn(msgType, responseMsg);
      cb && cb(responseMsg);
    }, (err) => {
      cb && cb(err);
    });
  }

  /**
   *
   * @param {function} cb - Callback which will be called on error and on success
   * @param {Object} [config] - Object holding various extra data on requests
   * @param {number} [config.timeout] - Maximum allowed request time in milliseconds
   * @api public
   */
  inquireIsWebhookDone(cb, config) {
    const totalTimeout = (config && config.timeout) || 20000;
    const webhookInquiryTimer = setTimeout(() => {
      cb && cb(new RequestStatusError());
    }, totalTimeout);
    this._inquireIsWebhookDone((webhookRes) => {
      clearTimeout(webhookInquiryTimer);
      cb && cb(webhookRes);
    }, config);
  }

  /**
   * @param {function} cb - Callback which will be called on error and on success
   * @param {Object} [config] - Object holding various extra data on requests
   * @param {number} [config.timeout] - Maximum allowed request time in milliseconds
   * @api public
   */
  waitTillWebhookDone(cb, config) {
    const webhookStateCb = (resOrErr) => {
      if (resOrErr instanceof Error) {
        cb(resOrErr);
      } else {
        if (typeof resOrErr === 'object' && resOrErr.success !== null && resOrErr.success !== undefined) {
          if (resOrErr.success === true) {
            if (resOrErr.webhookState && [possibleWebhookStates.failed, possibleWebhookStates.complete, possibleWebhookStates.irrelevant].includes(resOrErr.webhookState)) {
              cb(resOrErr);
            } else {
              this.waitTillWebhookDone(cb, config);
            }
          } else {
            cb(resOrErr);
          }
        } else {
          cb(resOrErr);
        }
      }
    };
    this.inquireIsWebhookDone(webhookStateCb, config);
  }

  /**
   * Used in order to send an image for analysis
   *
   * @param {function} cb - Callback which will be called on error and on success
   * @param {Blob} binaryImage - Image to be sent to server
   * @param {Object} [config] - Object holding various extra data on requests
   * @param {boolean} [config.doNotProcessFrame] - Whether no not analyze frame
   * @param {number} [config.timeout] - Maximum allowed request time in milliseconds
   * @param {number} [config.timestamp] - Timestamp to set on blob
   * @api public
   */
  sendFrame(cb, config, binaryImage) {
    const msgType = 'frame_request';
    this.logger.debug(`scanovate connection about to emit '${msgType}'`);
    let doNotProcessFrame = false;
    if (config && (config.doNotProcessFrame === true || config.doNotProcessFrame === false)) {
      doNotProcessFrame = config.doNotProcessFrame;
    } else {
      doNotProcessFrame = false;
    }
    const request = new RequestBuilder()
      .setDataToSend({ frame: binaryImage })
      .setExtraFields({
        should_process_frame: !doNotProcessFrame,
        message_type: msgType,
        timestamp: (config && config.timestamp) || '' + Date.now()
      });

    request.setTimeout((config && config.timeout) || 10000);
    this._sendRequestToServer(request).then((responseMsg) => {
      this.logSocketResponseFn(msgType, responseMsg);
      cb && cb(responseMsg);
    }, (err) => {
      cb && cb(err);
    });
  }

  sendVideo(cb, config, video, type) {
    const msgType = 'video_request';
    this.logger.debug(`scanovate connection about to emit '${msgType}'`);
    let doNotProcessFrame = false;
    if (config && (config.doNotProcessFrame === true || config.doNotProcessFrame === false)) {
      doNotProcessFrame = config.doNotProcessFrame;
    } else {
      doNotProcessFrame = false;
    }
    const request = new RequestBuilder()
      .setDataToSend({ video: video })
      .setExtraFields({
        should_process_frame: !doNotProcessFrame,
        message_type: msgType,
        timestamp: (config && config.timestamp) || '' + Date.now(),
        type: type
      });
    request.setTimeout((config && config.timeout) || 10000);
    this._sendRequestToServer(request).then((responseMsg) => {
      this.logSocketResponseFn(msgType, responseMsg);
      cb && cb(responseMsg);
    }, (err) => {
      cb && cb(err);
    });
  }

  sendPartVideo(cb, config, video, type, index, total) {
    const msgType = 'part_video_request';
    this.logger.debug(`scanovate connection about to emit '${msgType}'`);
    let doNotProcessFrame = false;
    if (config && (config.doNotProcessFrame === true || config.doNotProcessFrame === false)) {
      doNotProcessFrame = config.doNotProcessFrame;
    } else {
      doNotProcessFrame = false;
    }
    const request = new RequestBuilder()
      .setDataToSend({ part_video: video })
      .setExtraFields({
        should_process_frame: !doNotProcessFrame,
        message_type: msgType,
        timestamp: (config && config.timestamp) || '' + Date.now(),
        type: type,
        index: index,
        total: total
      });
    request.setTimeout((config && config.timeout) || 10000);
    this._sendRequestToServer(request).then((responseMsg) => {
      this.logSocketResponseFn(msgType, responseMsg);
      cb && cb(responseMsg);
    }, (err) => {
      cb && cb(err);
    });
  }

  static _errorToFailureObj(error) {
    const objToReturn = {
      errorMessage: error && (error.errorMessage || error.message)
    };
    const errorCode = error && (error.errorCode || error.code);
    errorCode && (objToReturn.errorCode = errorCode);
    return objToReturn;
  }

  /**
   *
   * @param {function | undefined} cb - Callback which will be called on error and on success
   * @param {string | undefined} baseURL - A server base url
   * @param {string | undefined} configFileName - A string that holds the configuration file name to get the styles
   * @param {number | undefined} timeout - A request timeout
   * @api public
   */
  async getDefaultStyles(baseURL, configFileName, confTimeout) {

    return new Promise(async (res, rej) => {
      console.log(`scanovate connection about to get the styles from '${configFileName}'`);
      const headers = {
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Flow-ID' : this.flowUUID,
        'X-Session-ID': this.sessionUUID,
        'X-Request-ID': uuidv4(),
      };

      let timeout = 7500;
      if (confTimeout) timeout = confTimeout;

      await axios.get(removeExtraSlashesFromURL(`${baseURL}/default_styles?clientTranslationFileName=${configFileName}`),
        {
          headers: headers,
          timeout: timeout,
          withCredentials: true,
        } 
      ).then(axiosResponse => {
        const status = axiosResponse.status;
        console.debug(`Request succeeded with status: ${status}`);
        
        res(axiosResponse.data);
      }).catch(error => {
        console.log({error});
        this.logger.error(`Request had error: ${(error && error.message) || error}`);
        if (error.response) {
          rej(new RequestStatusError(error.response.status,error.response.message));
        } else if (error.code === 'ECONNABORTED') {
           rej(new RequestTimedOutError());
        } else {
          rej(new RequestFailedError());
        }
      });
    })
  }

  /**
   * Used in order to report client side error to the server
   *
   * @param {function} cb - Callback which will be called on error and on success
   * @param {Object} failureObject - JSON to be sent to server as error
   * @param {number} failureObject.errorCode - Code of said error
   * @param {string} failureObject.errorMessage - Message of said error
   * @param {Object} [config] - Object holding various extra data on requests
   * @param {number} [config.timeout] - Maximum allowed request time in milliseconds
   * @param {boolean} [config.waitForWebhook] - Whether to wait for a response which confirm webhook is done
   * @api public
   */
  sendReportFailure(cb, config, failureObject) {
    const objToSend = (failureObject instanceof Error ? ConnectionManager._errorToFailureObj(failureObject) : failureObject);
    const msgType = 'report_failure';
    this.logger.debug(`scanovate connection about to emit '${msgType}'`);
    const request = new RequestBuilder()
      .setExtraFields({
        timestamp: '' + Date.now(),
        message_type: msgType,
        json_obj: JSON.stringify(objToSend || {})
      });

    const timeoutMs = (config && config.timeout) || 20000;
    request.setTimeout(timeoutMs);
    this._sendRequestToServer(request).then((responseMsg) => {
      this.logSocketResponseFn(msgType, responseMsg);
      if ((responseMsg && typeof responseMsg === 'object' && responseMsg.success !== false) && (!config || (config.waitForWebhook) !== false)) {
        this.waitTillWebhookDone((webhookRet) => {
          cb && cb(webhookRet);
        }, { timeout: timeoutMs });
      } else {
        cb && cb(responseMsg);
      }
    }, (err) => {
      cb && cb(err);
    });
  }

  /**
   * Used in order to report client side stage ending to the server
   *
   * @param {function} cb - Callback which will be called on error and on success
   * @param {Object} reportObject - JSON to be sent to server as error
   * @param {number} reportObject.stageOrdinal - Ordinal of the stage
   * @param {string} reportObject.stageName - Name of said stage
   * @param {Object} [config] - Object holding various extra data on requests
   * @param {number} [config.timeout] - Maximum allowed request time in milliseconds
   * @api public
   */
  sendReportStageEnding(cb, config, reportObject) {
    const msgType = 'report_stage_ending';
    this.logger.debug(`scanovate connection about to emit '${msgType}'`);
    const request = new RequestBuilder()
      .setExtraFields({
        timestamp: '' + Date.now(),
        message_type: msgType,
        json_obj: JSON.stringify(reportObject || {})
      });

    if (config && config.timeout) {
      request.setTimeout(config.timeout);
    }
    this._sendRequestToServer(request).then((responseMsg) => {
      this.logSocketResponseFn(msgType, responseMsg);
      cb && cb(responseMsg);
    }, (err) => {
      cb && cb(err);
    });
  }

  async _sendRequestToServer (requestBuilder) {
    const dataToSend = requestBuilder.dataToSend;
    const extraFields = requestBuilder.extraFields;

    const formData = new FormData();
    const formBuilding = (form, formDataToAppend) => {
      if (formDataToAppend && typeof formDataToAppend === 'object') {
        for (let field in formDataToAppend) {
          if (formDataToAppend.hasOwnProperty(field)) {
            form.append(field, formDataToAppend[field]);
          }
        }
      }
    };

    return new Promise(async (res, rej) => {
      formBuilding(formData, extraFields);
      formBuilding(formData, dataToSend);
      const headers = {
        'Content-Type': `multipart/form-data`,
        'X-Flow-ID' : this.flowUUID,
        'X-Session-ID': this.sessionUUID,
        'X-Request-ID': uuidv4(),
      };
      let timeout = 5000;
      if (requestBuilder && requestBuilder.timeout) timeout = requestBuilder.timeout;

      await axios.post(
        removeExtraSlashesFromURL(`${this.serverBaseUrl}${this.postInitialConnectionUrl}`),
        formData,
        {
          headers: headers,
          timeout: timeout,
          withCredentials: true,
        }
      ).then(axiosResponse => {
        this.logger.debug(`Request succeeded with status: ${axiosResponse.status}`);
        res(axiosResponse.data);
      }).catch(error => {
        console.log({error});
        this.logger.error(`Request had error: ${(error && error.message) || error}`);
        if (error.response) {
          rej(new RequestStatusError(error.response.status,error.response.message));
        } else if (error.code === 'ECONNABORTED') {
           rej(new RequestTimedOutError());
        } else {
          rej(new RequestFailedError());
        }
      });
    })
  }
}

export default ConnectionManager;