import React, { CSSProperties } from 'react';

import icons from '../../../assets/icons';

import CCSvgView from './CCSvg.view';

interface Props {
  readonly name: keyof typeof icons;
  readonly className?: string;
  readonly style?: CSSProperties;
  readonly onClick?: () => void;
}

const CCSvg: React.FC<Props> = (props: React.PropsWithChildren<Props>) => (
  <CCSvgView
    style={props.style}
    className={props.className}
    name={props.name}
    onClick={props.onClick}
    children={props.children}
    />
);

CCSvg.displayName = 'CCSvg';
CCSvg.defaultProps = {};

export default React.memo(CCSvg);
