import { Dict, ICPalette, ITranslationScan } from "../models/capture";

export const parseBoolean = (str :string | undefined | null) => /true/i.test(''+str);

export const getQueryVariable = (key:string) => {
  const history = window.location.search;
  const queryParams = new URLSearchParams(history);
  return queryParams.get(key);
}

export const mergeObjects = (objToBeTransformed: any, objWithExtraProps: any) => {
  let additionalPropsObj: object[];
  if (!Array.isArray(objWithExtraProps)) {
    additionalPropsObj = [objWithExtraProps];
  } else {
    additionalPropsObj = objWithExtraProps;
  }
  additionalPropsObj && additionalPropsObj.forEach(function (elem: any) {
    if (elem && typeof elem === 'object') {
      for (let attr in elem) {
        if (elem.hasOwnProperty(attr)) {
          objToBeTransformed[attr] = elem[attr];
        }
      }
    }
  });
  return objToBeTransformed;
}

export const drawRotatedImageOnCanvas = (ctx: CanvasRenderingContext2D, drawingFunc: () => void, ang: number = 90) => {
  if (ang === 90 || ang === 270) {
    if (ang === 90) ctx.setTransform(0, 1, -1, 0, ctx.canvas.width, 0);
    else ctx.setTransform(0, -1, 1, 0, 0, ctx.canvas.height);
  } else {
    if (ang === 0) ctx.setTransform(1, 0, 0, 1, 0, 0);
    else ctx.setTransform(-1, 0, 0, -1, ctx.canvas.width, ctx.canvas.height);
  }
  drawingFunc && drawingFunc();
  ctx.setTransform(1, 0, 0, 1, 0, 0);   // reset default transform
}

export const drawCPaletteOnContext = (cPalette: ICPalette, context: CanvasRenderingContext2D, width: number, height: number, numOfPixel: number) => {
  context.beginPath();
  context.fillStyle = cPalette.topLeft;
  context.fillRect(0, 0, numOfPixel, numOfPixel);
  context.fillStyle = cPalette.topRight;
  context.fillRect(width - numOfPixel, 0, numOfPixel, numOfPixel);
  context.fillStyle = cPalette.bottomRight;
  context.fillRect(width - numOfPixel, height - numOfPixel, numOfPixel, numOfPixel);
  context.fillStyle = cPalette.bottomLeft;
  context.fillRect(0, height - numOfPixel, numOfPixel, numOfPixel);
  context.closePath();
}

export const b64toBlob = (dataURI: string) => {
  const byteString = atob(dataURI.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], {type: 'image/jpeg'});
};

export const convertTranslationScanToITitles = (translationScan : ITranslationScan) => {
  const titlesMap: Dict = {
    scanCard:'SCAN_YOUR_CARD',
    scanFront:'SCAN_YOUR_CARD_FRONT_SIDE',
    scanBack:'SCAN_YOUR_CARD_BACK_SIDE',
    done:'DONE',
    turnIdOver:'TURN_ID_OVER',
    tiltCard:'TILT_YOUR_CARD',
    passive:'PASSIVE',
    center:'CENTER',
    right:'RIGHT',
    left:'LEFT',
    recordOTP:'RECORD_OTP',
    recordSTT:'RECORD_STT',
    recordGesture:'RECORD_GESTURE',
    finishRecord:'FINISH_RECORD',
    error:'ERROR',
  }

  const titles: Dict = {}
  for (const [name, value] of Object.entries(translationScan)) {
    titles[titlesMap[name]] = value
  }

  return titles;
}
