import browserDetector from './browserDetector'
import {
  CameraNoRelevantDeviceFound,
  GetUserMediaNotImplemented,
  CameraAccessAttemptWithoutSSL,
} from './customErrors'
import isBackCameraLabel from './backCamerasKeywords'

const debugModule = require('debug');
const debug = debugModule('modular-client:camera-adapter');

const _onFinalCameraSuccessLog = (stream: MediaStream) => { // TODO:: any?
  debug(`Camera final stream had been retrieved and now returned to caller`);
  return stream;
}

const _onFinalCameraFailureLog = (error: any) => { // TODO:: any?
  debug(`Camera final stream could not have been retrieved and an error is now returned to caller`);
  return Promise.reject(error);
}

const isEnvironmentMode = (constraints: any) => {
  const environmentString = 'environment';
  let environmentModeDetected = false;
  const videoConstraintRef = constraints && constraints.video && constraints.video.facingMode;
  if (typeof videoConstraintRef === 'string' && videoConstraintRef === environmentString) {
    environmentModeDetected = true;
  }
  else if (typeof videoConstraintRef === 'object') {
    for (let prop in videoConstraintRef) {
      if (videoConstraintRef.hasOwnProperty(prop)) {
        if (videoConstraintRef[prop] === environmentString) {
          environmentModeDetected = true;
          break;
        }
      }
    }
  }
  return environmentModeDetected;
}
const shouldAttemptGettingSpecificDeviceId = (constraints: any) => { // TODO:: any? MediaTrackConstraints not work
  const shouldReopenBeAttempted = (browserDetector.enum.CHROME === browserDetector.detectBrowser()) && isEnvironmentMode(constraints);
  if(shouldReopenBeAttempted) debug(`Camera constraints were found to include environment, and browser to be Chrome and therefore a suggestion of trying to open it again is given`);
  return shouldReopenBeAttempted;
}

const findDeviceIDBySize = async (findBig: boolean, callback: Function) => {
  return new Promise<string>(async (resolve,reject) => {    
    const devices = await window.navigator.mediaDevices.enumerateDevices();
    const filteredVideoDevices = devices.filter(curDevice => curDevice.kind === 'videoinput');
    if (filteredVideoDevices.length === 0) {
      reject(new CameraNoRelevantDeviceFound());
    }
    let selectedStreamCapabilities;

    for (let i = 0; i < filteredVideoDevices.length; i++) {
      const stream = await callback(
        {
          video: {
            deviceId: filteredVideoDevices[i].deviceId
          }
      });
              
      const streamCapabilities = stream.getVideoTracks()[0].getCapabilities();
      stopStream(stream);

      let selectedWidth = 0;
      let streamWidth = 0;
      if (streamCapabilities && streamCapabilities.width && streamCapabilities.width.max) {
        streamWidth = streamCapabilities.width.max;
      } else continue;

      if ((!selectedStreamCapabilities || selectedStreamCapabilities === undefined)) {
        selectedStreamCapabilities = streamCapabilities;
      } else {
        selectedWidth = selectedStreamCapabilities.width!.max!;
        if ((findBig && streamWidth > selectedWidth) || (!findBig && streamWidth < selectedWidth)){
          selectedStreamCapabilities = streamCapabilities;
        }
      }
    }

    selectedStreamCapabilities?.deviceId ? resolve(selectedStreamCapabilities.deviceId) : reject(new CameraNoRelevantDeviceFound()); 
  })
}

const findLastEnumeratedDeviceId = () => {
  return window.navigator.mediaDevices.enumerateDevices().then(async function (devices) {
    const filteredVideoDevices = devices.filter(function (elem) {
      return (elem.kind === 'videoinput');
    });

    let finalFilteredDevices = filteredVideoDevices.filter(function (elem) {
      return isBackCameraLabel(elem.label);
    });
    if (finalFilteredDevices.length === 0) {
      finalFilteredDevices = filteredVideoDevices;
      if (filteredVideoDevices.length > 0) {
        return finalFilteredDevices[finalFilteredDevices.length - 1].deviceId;
      }
      return Promise.reject(new CameraNoRelevantDeviceFound())
    }

    return finalFilteredDevices[finalFilteredDevices.length - 1].deviceId;
  });
}

const getOnCameraSuccess =  (isKiosk: boolean, constraints: any, cameraOpenFunc: (constraintsObj: any) => void) => { // TODO:: any?

  return (stream: any) => { // TODO:: any?
    if ((!constraints.video.tag || constraints.video.tag !== "SNWebcam") || (!browserDetector.isMobile() && !isKiosk)) {
      return stream;
    }

    if(!browserDetector.isMobile() && isKiosk) { //desk && kiosk
      const res = findDeviceIDBySize(!isEnvironmentMode(constraints),cameraOpenFunc).then((deviceId) => {
        if (deviceId) {
          const constraintsObj : any = {
            video: {
              deviceId: {exact: deviceId}
            }
          };
          constraints.audio && (constraintsObj.audio = constraints.audio);
          if(constraints.video) {
            constraints.video.height && (constraintsObj.video.height = constraints.video.height);
            constraints.video.width && (constraintsObj.video.width = constraints.video.width);
          }
          stopStream(stream);
          return cameraOpenFunc(constraintsObj);
        } else {
          return stream;
        }
      })
      return res;
    }
    else if (shouldAttemptGettingSpecificDeviceId(constraints)) {

      const res = findLastEnumeratedDeviceId().then((deviceId) => {
        if (deviceId) {
          const constraintsObj : any = {
            video: {
              deviceId: {exact: deviceId}
            }
          };
          constraints.audio && (constraintsObj.audio = constraints.audio);
          if(constraints.video){
            constraints.video.height && (constraintsObj.video.height = constraints.video.height);
            constraints.video.width && (constraintsObj.video.width = constraints.video.width);
          }
          stopStream(stream);
          return cameraOpenFunc(constraintsObj);
        } else {
          return stream;
        }
      });
      return res;
    } else return stream;
  }
}

const stopStream = (stream: MediaStream) => { 
  ((stream && stream.getTracks()) || []).forEach(function (track) {
    track.stop();
  });
}

const getOnCameraError = (constraints: any, cameraOpenFunc: (constraintsObj: any) => void) => { // TODO:: any?
  return function (error: any) { // TODO:: any?
    debug(`Had an error opening camera: ${error && error.message}`);
    if (shouldAttemptGettingSpecificDeviceId(constraints)) {
      debug(`Conditions are such that another opening the camera would be attempted`);
      return findLastEnumeratedDeviceId().then((deviceId) => {
        const constraintsObj : any= {
          video: {
            deviceId: {exact: deviceId}
          }
        };
        constraints.audio && (constraintsObj.audio = constraints.audio);
        if(constraints.video){
          constraints.video.height && (constraintsObj.video.height = constraints.video.height);
          constraints.video.width && (constraintsObj.video.width = constraints.video.width);
        }
        return cameraOpenFunc(constraintsObj);
      });
    } else return cameraOpenFunc(constraints);
  }
}

export const UserMediaAccess = (isKiosk = false) => {
  if (typeof window === 'undefined') return;
  const rootWindow = window;

  if (rootWindow.navigator.mediaDevices.getUserMedia === undefined) {
    rootWindow.navigator.mediaDevices.getUserMedia = function (constraints : any) {
      // First get ahold of the legacy getUserMedia, if present
      //@ts-ignore
      const getUserMedia = rootWindow.navigator.getUserMedia || rootWindow.navigator.webkitGetUserMedia || rootWindow.navigator.mozGetUserMedia || rootWindow.navigator.msGetUserMedia;

      // Some browsers just don't implement it - return a rejected promise with an error
      // to keep a consistent interface
      if (!getUserMedia) {
        if (window.location && window.location.protocol && window.location.protocol.includes('https')) {
          return Promise.reject(new GetUserMediaNotImplemented());
        } else {
          return Promise.reject(new CameraAccessAttemptWithoutSSL());
        }
      }

      const buildedFunc = function (constraints : any) {
        return new Promise(function (resolve, reject) {
          getUserMedia.call(rootWindow.navigator, constraints, resolve, reject);
        });
      };
      // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
      return buildedFunc(constraints).then(getOnCameraSuccess(isKiosk, constraints, buildedFunc), getOnCameraError(constraints, buildedFunc)).then(_onFinalCameraSuccessLog, _onFinalCameraFailureLog);
    };
  } else {
    const originalFuncBinded = rootWindow.navigator.mediaDevices.getUserMedia.bind(rootWindow.navigator.mediaDevices);
    rootWindow.navigator.mediaDevices.getUserMedia = function (constraints) {
      return originalFuncBinded(constraints).then(getOnCameraSuccess(isKiosk, constraints, originalFuncBinded), getOnCameraError(constraints, originalFuncBinded)).then(_onFinalCameraSuccessLog, _onFinalCameraFailureLog);
    };
  }
}

export default UserMediaAccess;
