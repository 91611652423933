import React, { Fragment, ReactNode } from 'react';

import { IResultHeader } from '../../../../models/result';
import CCResultDataCell from '../../../ui/CCResultDataCell/CCResultDataCell';
import CCResultImageCell from '../../../ui/CCResultImageCell/CCResultImageCell';

import classes from './StageResult.module.scss';

interface Props {
  children?: ReactNode;
  readonly title?: string;
  readonly headers: IResultHeader[];
  readonly fields: IResultHeader[];
  readonly images: IResultHeader[];
  readonly auths: IResultHeader[];
 }

const StageResultView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => (
  <Fragment>
    {props.title ? 
      <h1 className={classes['title']}>{props.title}</h1>
    : null}

    {props.headers.length > 0 ?
      props.headers.map((header, index) => {
        return(
          <CCResultDataCell
            title={header.title}
            message={header.message}
            key={index}
          />
        )
      })
    : null}

    {props.fields.length > 0 ?
      props.fields.map((field, index) => {
        return(
          <CCResultDataCell
            title={field.title}
            message={field.message}
            key={index}
          />
        )
      })
    : null}

    {props.auths.length > 0 ?
      props.auths.map((auth, index) => {
        return(
          <CCResultDataCell
            title={auth.title}
            message={auth.message}
            key={index}
          />
        )
      })
    : null}

    {props.images.length > 0 ?
      props.images.map((image, index) => {
        let imageSrc = image.message;
        if (!imageSrc.includes('data:image')) imageSrc = `data:image/jpeg;base64,${imageSrc}`;
        return(
          <CCResultImageCell
            title={image.title}
            imgSrc={imageSrc}
            key={index}
          />
        )
      })
    : null}

  </Fragment>
);

StageResultView.displayName = 'StageResultView';
StageResultView.defaultProps = {};

export default StageResultView;
