import React, { CSSProperties } from 'react';

import CaptureInstructionView from './CaptureInstruction.view';

interface Props {
  readonly instructionTop: number;
  readonly labelStyle: CSSProperties;
  readonly instructionLabelText: string;
 }

const CaptureInstruction: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {

  return (
    <CaptureInstructionView
      instructionTop={props.instructionTop}
      labelStyle={props.labelStyle}
      instructionLabelText={props.instructionLabelText}
      children={props.children}
    />
  );
}

CaptureInstruction.displayName = 'CaptureInstruction';
CaptureInstruction.defaultProps = {};

export default React.memo(CaptureInstruction);
