import React, { CSSProperties } from 'react';

import classes from './CCGestureSvg.module.scss';

interface Props {
  readonly size: string;
  readonly dangerouslySetInnerHTML?: {
    __html: string;
  } | undefined;
  readonly className?: string;
  readonly style?: CSSProperties;
  readonly onClick?: () => void;
}

const CCGestureSvgView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const svgClasses: string = classes['container'] + (props.className ? ` ${props.className}` : '');

  return (
    <svg
      style={props.style}
      className={svgClasses}
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={'0 0 ' + props.size}
      dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
      onClick={props.onClick}
    ></svg>
  );
};

CCGestureSvgView.displayName = 'CCGestureSvgView';
CCGestureSvgView.defaultProps = {};

export default CCGestureSvgView;
