import { AutoTextSize } from 'auto-text-size';
import React, { CSSProperties, ReactNode } from 'react';

import classes from './CaptureInstruction.module.scss';

interface Props {
  children?: ReactNode;
  readonly instructionTop: number;
  readonly labelStyle: CSSProperties;
  readonly instructionLabelText: string;
 }

const CaptureInstructionView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => (
  <div
    className={classes['container']}
    style={{top: props.instructionTop}}
  >
    <div 
      className={classes['container__label']}
      style={props.labelStyle}
      dir='auto'
    >
      <AutoTextSize
        className={classes['container__text']}
        style={props.labelStyle}
        mode={`oneline`}
      >{props.instructionLabelText}</AutoTextSize>
    </div>
  </div>
);

CaptureInstructionView.displayName = 'CaptureInstructionView';
CaptureInstructionView.defaultProps = {};

export default CaptureInstructionView;
