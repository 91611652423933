import React, { ReactNode } from "react";

import CaptureInstruction from '../../CommonOverlayComponents/CaptureInstruction/CaptureInstruction';
import PaintedRectTarget from '../../CommonOverlayComponents/PaintedRectTarget/PaintedRectTarget';
import { 
  IRect, 
  IStylesCustomizations, 
} from '../../../../../models/capture';

import classes from './SingleCaptureOverlay.module.scss';

interface Props { 
  children?: ReactNode;
  readonly paintedRectTarget: IRect;
  readonly instructionTop: number;
  readonly instructionFontSize: number;
  readonly styles: IStylesCustomizations;
  readonly instructionLabelText: string;
  readonly captureButtonImage: string;
  readonly buttonRect: IRect;
  readonly isButtonDisable: boolean;
  readonly captureClickHandler: () => void;
}

const SingleCaptureOverlayView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => (
  <div className={classes['container']}>
    <PaintedRectTarget
      showPaintedRectTarget={true}
      targetRect={props.paintedRectTarget}
    />
    <CaptureInstruction
      instructionTop={props.instructionTop}
      labelStyle={{
        width: props.paintedRectTarget.width,
        color: props.styles.instructionLabelColor,
        fontFamily: props.styles.instructionLabelFontFamily,
        backgroundColor: props.styles.instructionLabelBg,
        borderRadius: props.styles.instructionLabelBorderRadius,
        fontSize: props.instructionFontSize
      }}
      instructionLabelText={props.instructionLabelText}
    />
    <object
      className={classes['container__button']}
      type="image/svg+xml"
      data={props.captureButtonImage}
      style={{...props.buttonRect}}
      aria-labelledby=" "
    />
    <div
      className={classes['container__button']}
      style={{
        ...props.buttonRect,
        zIndex:7
      }}
      onClick={props.isButtonDisable ? () => {} : () => props.captureClickHandler()}
    />
  </div>
);

SingleCaptureOverlayView.displayName = 'SingleCaptureOverlayView';
SingleCaptureOverlayView.defaultProps = {};

export default SingleCaptureOverlayView;
