/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState, ReactNode } from 'react';
import Webcam from "react-webcam";

import * as SNUtils from '../../../../../utils/utils';
import {
  StageCodes,
  IRect,
  ICPalette,
} from '../../../../../models/capture';

import CaptureProcessingWebcamView from './CaptureProcessingWebcam.view';

const MAX_SMALL_FRAMES_AT_START = 5;

interface Props {
  children?: ReactNode;
  readonly cameraPreviewRect: IRect;
  readonly isConnect: boolean;
  readonly stream?: MediaStream;
  readonly needToRecordAudio: boolean;
  readonly needToRecordVideo: boolean;
  readonly mirrored: boolean;
  readonly cameraFacingMode?: string;
  readonly stageCode: number;
  readonly cPalette?: ICPalette;
  readonly isRotate90: boolean;
  readonly isKioskLiveness: boolean;
  readonly needNewFrame: boolean;
  readonly mimeType?: string | null;
  readonly onUserMedia: (stream: MediaStream) => void;
  readonly onUserMediaError :(error: string | DOMException) => void;
  readonly sendFrame: (frame: Blob) => void;
  readonly sendVideoParts: (videoParts: Blob[]) => void;
 }

const CaptureProcessingWebcam: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {

  const webcamRef = useRef<Webcam>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);

  let smallFrameAtStartCounter = 0;
  let frameInProcess = false;

  const [frameSendingAllowedState, setFrameSendingAllowedState] = useState<boolean>(false);
  const [generateBlobTimeoutState, setGenerateBlobTimeoutState] = useState<any | null>(null);
  const [lastImageState, setLastImageState] = useState<Blob | null>(null);
  const [isVideoMetadataReadyState, setIsVideoMetadataReadyState] = useState<boolean>(false);

  const [isRecordingState, setIsRecordingState] = useState<boolean>(false);
  const [recordedChunksState, setRecordedChunksState] = useState<Blob[]>([]);
  
  useEffect(() => {
    return () => {
      generateBlobTimeoutState && clearTimeout(generateBlobTimeoutState);
    };
  }, []);

  useEffect(() => {
    if (!props.isConnect) return;

    const onCanPlay = () => {
      const videoWidth = webcamRef?.current?.video?.videoWidth;
      const videoHeight = webcamRef?.current?.video?.videoHeight;

      if (videoWidth && videoHeight && videoWidth > 0 && videoHeight > 0) {
        setIsVideoMetadataReadyState(true);
        props.needToRecordVideo && startRecording();
        generateBlob();
        webcamRef.current?.video?.removeEventListener('canplay', onCanPlay, false);
      }
    }

    const videoReadyState = webcamRef.current?.video?.readyState;

    if (videoReadyState && videoReadyState >= 3) onCanPlay();
    else webcamRef.current?.video?.addEventListener('canplay', onCanPlay, false);
    return () => {
      webcamRef.current?.video?.removeEventListener('canplay', onCanPlay, false);
    }

  }, [webcamRef, props.isConnect, props.needToRecordVideo]);

  useEffect(() => {
    if (props.needNewFrame && isVideoMetadataReadyState) capture();
    else setFrameSendingAllowedState(false);

  }, [props.needNewFrame, isVideoMetadataReadyState]);

  useEffect(() => {
    if ([StageCodes.Completed, StageCodes.Error].includes(props.stageCode)) {
      props.needToRecordVideo && stopRecording();
      return;
    };
    if ([StageCodes.Timeout].includes(props.stageCode)) props.needToRecordVideo && stopRecording();
    if (lastImageState && frameSendingAllowedState) {
      props.sendFrame(lastImageState);
      setFrameSendingAllowedState(false);
      setLastImageState(null);
    }

  }, [frameSendingAllowedState, lastImageState, props.stageCode]);

  const startRecording = useCallback(() => {
    if (!isRecordingState && props.mimeType) {
      if (webcamRef && webcamRef.current && webcamRef.current.stream) {
        setIsRecordingState(() => true);

        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
          mimeType: `video/${props.mimeType}`,
        });
        
        mediaRecorderRef.current.addEventListener(
          "dataavailable",
          handleDataAvailable
        );
        const dataTiming = props.mimeType === "mp4" ? 2000 : 5000;
        mediaRecorderRef.current.start(dataTiming);
      }
    }
  }, [webcamRef, setIsRecordingState, isRecordingState, props.mimeType]);

  // let needToHandleDataAvailable = true;
  const handleDataAvailable = useCallback((ev: BlobEvent) => {
    if (ev.data.size > 0){
      setRecordedChunksState((prev) => prev.concat(ev.data));
    }
    if (mediaRecorderRef.current?.state === 'inactive') setIsRecordingState(() => false);
  },[setRecordedChunksState, mediaRecorderRef]);

  const stopRecording = useCallback(() => {
    if (mediaRecorderRef.current?.state !== 'inactive'){
      mediaRecorderRef.current?.stop(); 
    }
  }, [isRecordingState, setIsRecordingState, mediaRecorderRef]);

  useEffect(() => {
    if (props.needToRecordVideo && !isRecordingState && recordedChunksState.length > 0 && props.mimeType) {
      props.sendVideoParts(recordedChunksState);

      setRecordedChunksState([]);
    } 
  }, [isRecordingState, recordedChunksState, props.needToRecordVideo]);

  const capture = useCallback(() => {
    setFrameSendingAllowedState(true);
  },[webcamRef]);

  const generateBlob = () => {
    generateBlobTimeoutState && clearTimeout(generateBlobTimeoutState);

    const currentRef = webcamRef && webcamRef.current;
    const currentVid = currentRef && currentRef.video;
    const currVidHeight = currentVid && currentVid.videoHeight;
    const currVidWidth = currentVid && currentVid.videoWidth;

    if (!currentVid || [StageCodes.Completed, StageCodes.Error, StageCodes.Timeout].includes(props.stageCode)) return;
    if (frameInProcess === false && currentVid && currVidHeight && currVidWidth && props.cPalette !== null) {
      frameInProcess = true;
      const videoWidth = currVidWidth;
      const videoHeight = currVidHeight;

      let canvas = currentRef?.getCanvas({width: videoWidth, height: videoHeight});
      
      if (!canvas) {
        frameInProcess = false;
        return;
      }
      
      if(props.isKioskLiveness && videoWidth > 2000) {
        var region = {
          x: videoWidth/3, 
          y: 0, 
          width: videoWidth/3, 
          height: videoHeight
        };

        var croppedCanvas = document.createElement("canvas");
        croppedCanvas.width = region.width;
        croppedCanvas.height = region.height;

        var cCtx = croppedCanvas.getContext("2d");
        if (cCtx) {
          cCtx.drawImage(canvas, region.x, region.y, region.width, region.height, 0, 0, region.width, region.height);

          props.cPalette && SNUtils.drawCPaletteOnContext(props.cPalette, cCtx, croppedCanvas?.width!, croppedCanvas?.height!, 4);

          croppedCanvas?.toBlob((blob) => {
            if (!webcamRef.current || [StageCodes.Completed, StageCodes.Error, StageCodes.Timeout].includes(props.stageCode)) return;
            if (smallFrameAtStartCounter < MAX_SMALL_FRAMES_AT_START) {
              if (blob && blob.size < 10000) {
                smallFrameAtStartCounter++;
                generateBlobTimeoutState && clearTimeout(generateBlobTimeoutState);
                setGenerateBlobTimeoutState(() => (setTimeout(generateBlob, 0)));
                frameInProcess = false;
                return;
              } else smallFrameAtStartCounter = MAX_SMALL_FRAMES_AT_START;
            }

            setLastImageState(blob);
            frameInProcess = false;
            generateBlobTimeoutState && clearTimeout(generateBlobTimeoutState);
            setGenerateBlobTimeoutState((setTimeout(generateBlob, 0)));
          }, 'image/jpeg', 0.95);
        } else {
          frameInProcess = false;
          return;
        }                   
      } else {
        const max = Math.max(videoWidth, videoHeight);
        const min = Math.min(videoWidth, videoHeight);
        
        if (props.isRotate90) {
          if (canvas.width !== max) canvas.width = max;
          if (canvas.height !== min) canvas.height = min;
        }
        else {
          canvas.width = videoWidth;
          canvas.height = videoHeight;
        }
        
        const context = canvas.getContext("2d");
        if (context) {
          if (videoHeight > videoWidth && props.isRotate90) {
            SNUtils.drawRotatedImageOnCanvas(
              context,
              () => {
                canvas = currentRef?.getCanvas({width: videoWidth, height: videoHeight});
              },
              270
            );
          } 
          else canvas = currentRef?.getCanvas({width: videoWidth, height: videoHeight}); 

          props.cPalette && SNUtils.drawCPaletteOnContext(props.cPalette, context, canvas?.width!, canvas?.height!, 4);

          canvas?.toBlob((blob) => {
            if (!webcamRef.current || [StageCodes.Completed, StageCodes.Error, StageCodes.Timeout].includes(props.stageCode)) return;
            if (smallFrameAtStartCounter < MAX_SMALL_FRAMES_AT_START) {
              if (blob && blob.size < 10000) {
                smallFrameAtStartCounter++;
                generateBlobTimeoutState && clearTimeout(generateBlobTimeoutState);
                setGenerateBlobTimeoutState(() => (setTimeout(generateBlob, 0)));
                frameInProcess = false;
                return;
              } else smallFrameAtStartCounter = MAX_SMALL_FRAMES_AT_START;
            }

            setLastImageState(blob);
            frameInProcess = false;
            generateBlobTimeoutState && clearTimeout(generateBlobTimeoutState);
            setGenerateBlobTimeoutState((setTimeout(generateBlob, 0)));
          }, 'image/jpeg', 0.95);
        } else {
          frameInProcess = false;
          return;
        }
      }
    } else {
      generateBlobTimeoutState && clearTimeout(generateBlobTimeoutState);
      setGenerateBlobTimeoutState(setTimeout(generateBlob, 50));
    }
  }

  return (
    <CaptureProcessingWebcamView
      isConnect={props.isConnect}
      stream={props.stream}
      cameraPreviewRect={props.cameraPreviewRect}
      webcamRef={webcamRef}
      audioConstraints={props.needToRecordAudio}
      mirrored={props.mirrored}
      cameraFacingMode={props.cameraFacingMode}
      isKioskLiveness={props.isKioskLiveness}
      needToRecordVideo={props.needToRecordVideo}
      onUserMedia={props.onUserMedia}
      onUserMediaError={props.onUserMediaError}
      children={props.children}
      />
  );
};

CaptureProcessingWebcam.displayName = 'CaptureProcessingWebcam';
CaptureProcessingWebcam.defaultProps = {};

export default CaptureProcessingWebcam;
