import { useState, useEffect } from 'react';

export interface ISize {
  width: number; 
  height: number
}

const useWindowSize = () =>  {
  const [windowSize, setWindowSize] = useState<ISize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {

      setTimeout(() => {
        setWindowSize(() => ({
          width: window.innerWidth,
          height: window.innerHeight,
        }));

        window.scrollTo(0,0);
      }, 501);
    };
    
    window.addEventListener('resize', handleResize);
    window.addEventListener("orientationchange", handleResize);

    handleResize();
    
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
    
  }, []);
  
  return windowSize;
};

export default useWindowSize;