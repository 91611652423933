import React from 'react';

import CCResultDataCellView from './CCResultDataCell.view';

interface Props { 
  readonly title: string;
  readonly message: string;
}

const CCResultDataCell: React.FC<Props> = (props: React.PropsWithChildren<Props>) => (
  <CCResultDataCellView
    title={props.title}
    message={props.message}
    children={props.children}
    />
);

CCResultDataCell.displayName = 'CCResultDataCell';
CCResultDataCell.defaultProps = {};

export default CCResultDataCell;
