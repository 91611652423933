/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';

import { ISize } from '../../../../hooks/use-window-size';
import {
  defaultStyles,
  Dict,
  ISingleCaptureParams,
  IStylesCustomizations
} from '../../../../models/capture';
import {
  CouldNotGetMediaStreamError,
  ScanovateError,
  ServerError
} from '../../../../helpers/customErrors';

import SingleCaptureView from './SingleCapture.view';

import ConnectionManager from '../../../../utils/communication';

interface Props {
  readonly size: ISize;
  readonly singleCaptureParams: ISingleCaptureParams;
  readonly onComplete: (res: Dict) => void; // TODO::?? change dict to err interface?
  readonly onError: (err: Dict) => void; // TODO::?? change dict to err interface?
}

const SingleCapture: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {

  const webcamRef = useRef<Webcam>(null);

  const [stylesState, setStylesState] = useState<IStylesCustomizations>(defaultStyles);
  const [streamState, setStreamState] = useState<MediaStream | undefined>(undefined);
  const [canSendCallbackState, setCanSendCallbackState] = useState<boolean>(true);
  const [showCameraState, setShowCameraState] = useState<boolean>(false);
  const [isButtonDisableState, setIsButtonDisableState] = useState<boolean>(false);
  const [numberOfEmptyImageState, setNumberOfEmptyImageState] = useState<number>(1);
  const MAX_EMPTY_IMAGES = 3;

  useEffect(() => {
    const connectionManager = new ConnectionManager(undefined,undefined,undefined,null);

    connectionManager.getDefaultStyles(props.singleCaptureParams.url, props.singleCaptureParams.clientTranslationFileName, 10000).then((res: any) => {
        
      if (res.success) {
        if (res.styles) setStylesState((prev) => ({...prev, ...res.styles}));
      }
      setShowCameraState(true);
    }).catch((err: any) => {
      console.error(err)
      setShowCameraState(true);
    });
  }, []);

  const handleError = (err: any) => { //TODO:: change any to doronError.
    if (typeof err === "object" && err.errorCode) err = new ServerError(err.errorMessage, err.errorCode)

    if (canSendCallbackState) {
      setCanSendCallbackState(false);
      props.onError(err);
    }
  }

  const captureClickHandler = React.useCallback(() => {
    setIsButtonDisableState(true);
    const imageSrc = webcamRef?.current?.getScreenshot();
    if (typeof imageSrc === 'string' && /\S/.test(imageSrc)) {
      if (canSendCallbackState) {
        setCanSendCallbackState(false);
        props.onComplete({
          "action_type": "complete",
          "status": "success",
          "success": true,
          "stages": [
            {
              "action_type": "stage",
              "payload": {
                "images": {
                  "input_image": imageSrc
                }
              },
              "stage": {
                "order": 1,
                "type": "capture"
              }
            }
          ]
        });
      }
    }
    else {
      if (numberOfEmptyImageState < MAX_EMPTY_IMAGES) {
        setNumberOfEmptyImageState((prev) => prev + 1);
        setIsButtonDisableState(false);
      } else {
        const myErr = new CouldNotGetMediaStreamError('CouldNotGetScreenshotError', 'Card capture returned an empty image');
        props.onError(myErr);
      }
    }
  }, [webcamRef, webcamRef.current, numberOfEmptyImageState, setNumberOfEmptyImageState]);


  const onUserMedia = (stream: MediaStream) => setStreamState(stream);

  const onUserMediaError = (error: any) => { //TODO not work with string | DOMException
    if (!(error instanceof ScanovateError) && typeof error === 'object') {
      error = new CouldNotGetMediaStreamError(error.name, error.message);
    }
    handleError(error);
  }

  return (
    <SingleCaptureView
      size={props.size}
      styles={stylesState}
      instructionLabelText={props.singleCaptureParams.instructionText}
      stream={streamState}
      webcamRef={webcamRef}
      showCamera={showCameraState}
      isButtonDisable={isButtonDisableState}
      captureClickHandler={captureClickHandler}
      onUserMedia={onUserMedia}
      onUserMediaError={onUserMediaError}
      children={props.children}
      />
  );
};

SingleCapture.displayName = 'SingleCapture';
SingleCapture.defaultProps = {};

export default SingleCapture;
