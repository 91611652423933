import React, { ReactNode } from "react";
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';

import CCSvg from '../../../ui/CCSvg/CCSvg';

import classes from './SettingsDialog.module.scss';

interface Props { 
  children?: ReactNode;
  readonly isOpen: boolean;
  readonly flowConfigName: string;
  readonly dialogOpenHandler: () => void;
  readonly dialogCloseHandler: () => void;
  readonly flowConfigChangedHandler: (newName: string) => void;
  readonly submitSettingsHandler: () => void;
}

const SettingsDialogView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => (
  <div className={classes['container']}>
    <CCSvg
      className={classes['container__icon']}
      name="settings"
      onClick={props.dialogOpenHandler}
    />
    <Dialog
      open={props.isOpen}
      onClose={props.dialogCloseHandler}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Settings</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Flow Config Name"
          defaultValue={props.flowConfigName}
          onChange={(e) => props.flowConfigChangedHandler(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.dialogCloseHandler} color="primary">
          Cancel
        </Button>
        <Button onClick={props.submitSettingsHandler} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  </div>
);

SettingsDialogView.displayName = 'settingsDialogView';
SettingsDialogView.defaultProps = {};

export default SettingsDialogView;
