import { motion } from 'framer-motion';
import React, { ReactNode } from "react";
import Webcam from 'react-webcam';

import { ISize } from '../../../../hooks/use-window-size';
import { IStylesCustomizations, visibilityVariant } from '../../../../models/capture';
import SingleCaptureOverlay from './SingleCaptureOverlay/SingleCaptureOverlay';
import SingleCaptureWebcam from './SingleCaptureWebcam/SingleCaptureWebcam';
import SpinnerImgSrc from '../../../../assets/svg/spinner.svg'

import classes from './SingleCapture.module.scss';

interface Props {
  children?: ReactNode
  readonly stream?: MediaStream;
  readonly size: ISize;
  readonly styles: IStylesCustomizations;
  readonly instructionLabelText: string;
  readonly webcamRef: React.RefObject<Webcam>
  readonly showCamera: boolean;
  readonly isButtonDisable: boolean;
  readonly captureClickHandler: () => void;
  readonly onUserMedia: (stream: MediaStream) => void;
  readonly onUserMediaError: (error: string | DOMException) => void;
 }

const SingleCaptureView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => (
  <div>
    <motion.div
      className={classes['container']}
      style={{...props.size}}
      variants={visibilityVariant("block", 0.75, false)}
      animate={(props.stream && props.showCamera) ? "visible" : "hidden"}
      initial="hidden"
    >
      <SingleCaptureWebcam
        size={props.size}
        webcamRef={props.webcamRef}
        onUserMedia={props.onUserMedia}
        onUserMediaError={props.onUserMediaError} 
      />
      <SingleCaptureOverlay
        isStream={props.stream ? true : false}
        size={props.size}
        styles={props.styles}
        instructionLabelText={props.instructionLabelText}
        isButtonDisable={props.isButtonDisable}
        captureClickHandler={props.captureClickHandler}
      />
    </motion.div>
    <motion.img
      className={classes['spinner']}
      src={SpinnerImgSrc}
      alt=""
      variants={visibilityVariant("inline", 0.25, true)}
      animate={props.showCamera ? "hidden" : "visible"}
      initial="visible"
    />
  </div>
);

SingleCaptureView.displayName = 'SingleCaptureView';
SingleCaptureView.defaultProps = {};

export default SingleCaptureView;
