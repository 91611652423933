/* eslint-disable react-hooks/exhaustive-deps */
import React, { CSSProperties, useEffect, useState } from 'react';

import CCGestureSvgView from './CCGestureSvg.view';

export enum GestureBarIconState {
  Current,
  Future,
  Past,
}

interface Props {
  readonly state: GestureBarIconState;
  readonly name: string[];
  readonly className?: string;
  readonly style?: CSSProperties;
  readonly onClick?: () => void;
}

const CCGestureSvg: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {

  const [dangerouslySetInnerHTMLState, setDangerouslySetInnerHTMLState] = useState<{__html: string;}>();

  useEffect(() => {

    switch (props.state) {
      case GestureBarIconState.Current:
        setDangerouslySetInnerHTMLState({ __html: props.name[1] });
        break;
      case GestureBarIconState.Future:
        setDangerouslySetInnerHTMLState({ __html: props.name[2] });
        break;
      case GestureBarIconState.Past:
        setDangerouslySetInnerHTMLState({ __html: props.name[3] });
        break;
    }

  }, [props.state]);

  return (
    <CCGestureSvgView
      size={props.name[0]}
      style={props.style}
      className={props.className}
      dangerouslySetInnerHTML={dangerouslySetInnerHTMLState}
      onClick={props.onClick}
    />
  );
};

CCGestureSvg.displayName = 'CCGestureSvg';
CCGestureSvg.defaultProps = {};

export default React.memo(CCGestureSvg);
