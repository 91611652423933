import React, { ReactNode } from "react";

import useStageResult from '../../../../hooks/use-stage-result';
import { Dict } from '../../../../models/capture';

import StageResultView from './StageResult.view';

interface Props { 
  children?: ReactNode;
  readonly title?: string;
  readonly payload?: Dict | string;
}

const StageResult: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {

  const {headersState, fieldsState, imagesState, authsState}  = useStageResult(props.payload)

  return (
    <StageResultView
      title={props.title}
      headers={headersState}
      fields={fieldsState}
      images={imagesState}
      auths={authsState}
      children={props.children}
      />
  );
};

StageResult.displayName = 'StageResult';
StageResult.defaultProps = {};

export default StageResult;
