import React from 'react';

import CCResultImageCellView from './CCResultImageCell.view';

interface Props { 
  readonly title: string;
  readonly imgSrc: string;
}

const CCResultImageCell: React.FC<Props> = (props: React.PropsWithChildren<Props>) => (
  <CCResultImageCellView
    title={props.title}
    imgSrc={props.imgSrc}
    children={props.children}
    />
);

CCResultImageCell.displayName = 'CCResultImageCell';
CCResultImageCell.defaultProps = {};

export default CCResultImageCell;
