import React, { useMemo, useRef, useState } from 'react';

import * as SNUtils from './utils/utils'
import AppView from './App.view';
import useQuery from './hooks/use-query';
import UserMediaAccess from './helpers/mediaDeviceAdapter';
import { 
  Dict,
  IConnectionParams,
  ISingleCaptureParams}
from './models/capture';

interface Props { }

const getUrl = window.location; 
const URL = `${getUrl.protocol || 'https'}//${getUrl.host}`;
const connectionUrl = SNUtils.getQueryVariable('connectionUrl');
const postConnectionUrl = SNUtils.getQueryVariable('postConnectionUrl');

const connectionParamTemp = {
  url: URL, 
  extraData: {
    // "webhookInjectionParams": {"matan": "doron_hook_test"},
    // sttLanguage:'en-US',
    // flowConfigName: 'mathilda.json',
    clientTranslationFileName: "scanovate_default_en.json",
    stagesConfiguration: {
      otp: {
        number: "1111"
      }
    }
  },
  relativeUrls: {
    connectionUrl: (window as any).__PUBLIC_URL__ + `${connectionUrl || '/ocr/init'}`,
    postConnectionUrl: (window as any).__PUBLIC_URL__ + `${postConnectionUrl || '/ocr/test_fps'}`
  }
};

const singleCaptureParamsTemp: ISingleCaptureParams = {
  url: URL,
  clientTranslationFileName: "scanovate_default_en.json",
  instructionText: "Take a photo of your card",
}

// //TODO:: comment on production
// const URL_DEV = "https://uat-dev1-ocr.scanovate.com";//OCR_URL_DEV
// const URL_DEV = "https://qaocr.scanovateoncloud.com";//OCR_URL_QA
// const URL_DEV = "https://uat-dev1-liveness.scanovate.com";//LIVENESS_URL_DEV
// const URL_DEV = "https://qaliveness.scanovateoncloud.com";//LIVENESS_URL_QA
//  const URL_DEV = "https://199.203.104.69:4090";//DORON_URL
// const URL_DEV = "https://e354-199-203-104-69.eu.ngrok.io"; //LIOR_URL
const URL_DEV = 'https://uat-release-pgw.scanovate.com';

const App: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {

  const query = useQuery(['is_webview', 'is_iframe', 'is_kiosk', 'rotate_passport', 'patience', 'callback_url', 'params', 'singleCaptureParams', 'translationPath']);

  const isWebview = useMemo(() => SNUtils.parseBoolean(query.is_webview), [query.is_webview]);
  const isIframe = useMemo(() => SNUtils.parseBoolean(query.is_iframe), [query.is_iframe]);
  const isKiosk = useMemo(() => SNUtils.parseBoolean(query.is_kiosk), [query.is_kiosk]);
  const isRotatePassport = useMemo(() => SNUtils.parseBoolean(query.rotate_passport), [query.rotate_passport]);
  const callbackUrl = query.callback_url;
  const queryConnectionParams: null | IConnectionParams = query.params && JSON.parse(query.params);
  const querySingleCaptureParams: null | ISingleCaptureParams = query.singleCaptureParams && JSON.parse(query.singleCaptureParams);
  const showPatienceLabel = SNUtils.parseBoolean(query.patience);
  const translationPath = query.translationPath;

  const userMediaAccessCalled = useRef(false);

  const callUserMediaAccessOnce = () => {
    if (!userMediaAccessCalled.current) {
      UserMediaAccess(isKiosk);
      userMediaAccessCalled.current = true;
    }
  }

  callUserMediaAccessOnce();

  const getConnectionParams = () => {
    if (!queryConnectionParams) return null;
    else if (!queryConnectionParams?.url) return SNUtils.mergeObjects(queryConnectionParams, {url: URL});
    else return queryConnectionParams;
  }

  const [connectionParamsState, setConnectionParamsState] = useState<IConnectionParams | null>(getConnectionParams());
  const [singleCaptureParamsState, setSingleCaptureParamsState] = useState<ISingleCaptureParams | null>(querySingleCaptureParams)
  const [resultState, setResultState] = useState<Dict>({});
  const [showResultState, setShowResultState] = useState<boolean>(false);

  const startComponentHandler = (caseId: string, libName: string, extraDataAdditions: object, flowConfigName: string) => {
    if (libName === 'CAPTURE') {
      setSingleCaptureParamsState(() => SNUtils.mergeObjects(singleCaptureParamsTemp, {
        url: URL_DEV, //TODO:: comment on prod
      }));
    } else {
      setConnectionParamsState(() => SNUtils.mergeObjects(connectionParamTemp, {
        url: URL_DEV, //TODO:: comment on prod        
        extraData: SNUtils.mergeObjects(connectionParamTemp.extraData, [
          extraDataAdditions,
          {
            flowConfigName,
            caseId,
            libraryName:libName
          }
        ])
      }));
    }
  }

  const captureCompleteHandler = (res: Dict) => { //TODO::change to res
    setTimeout(() => {
      console.log("onComplete from scanovate: ", res);
      if (isIframe) {
        window.parent.postMessage(JSON.stringify(res), "*");
        return;
      }
      if (isWebview) {
        let url: string;

        if (callbackUrl){
          url = callbackUrl + '?res=' + encodeURIComponent(JSON.stringify(res));
        } else {
          url = 'SNWebClientCallback://onComplete?res=' + encodeURIComponent(JSON.stringify(res));
          window.parent.postMessage(JSON.stringify(res), "*");
        }
        window.location.replace(url);
      } else {
        setResultState(res);
        setConnectionParamsState(null);
        setSingleCaptureParamsState(null);
        setShowResultState(true);
      }
    }, singleCaptureParamsState ? 0 : 1500);
  }

  const captureErrorHandler = (err: Dict) => { //TODO::change to err
  
    setTimeout(() => {
      console.log("onError from scanovate: ", err);
      if (isIframe) {
        window.parent.postMessage(JSON.stringify(err), "*");
        return;
      }
      if (isWebview) {
        let url: string;

        if (callbackUrl){
          url = callbackUrl + '?err=' + encodeURIComponent(JSON.stringify(err));
        } else {
          url = 'SNWebClientCallback://onError?err=' + encodeURIComponent(JSON.stringify(err));
          window.parent.postMessage(JSON.stringify(err), "*");
        }
        window.location.replace(url);
      } else {
        setResultState(err);
        setSingleCaptureParamsState(null);
        setConnectionParamsState(null);
        setShowResultState(true);
      }
    }, singleCaptureParamsState ? 0 : 1500);
  }

  const resultCompleteHandler = () => setShowResultState(false);

  /*    const onFileChange = (event) => {
        window.doron_file = event.target.files[0];
        console.log('file loaded');
        // const input = window.document.getElementById("upload_upload");
        // const file = input.files[0];
        // const reader = new FileReader();
        // reader.addEventListener('load', (result) => {
        //     window.doron_file = result;
        //     console.log('file loaded');
        // });
        // reader.readAsText(file);
    };
    <input type="file" onChange={onFileChange} />*/

  return (
    <AppView 
      connectionParams={connectionParamsState}
      singleCaptureParams={singleCaptureParamsState}
      showPatienceLabel={showPatienceLabel}
      translationPath={translationPath}
      result={resultState}
      showResult={showResultState}
      isKiosk={isKiosk}
      isRotatePassport={isRotatePassport}
      startComponentHandler={startComponentHandler}
      captureCompleteHandler={captureCompleteHandler}
      captureErrorHandler={captureErrorHandler}
      resultCompleteHandler={resultCompleteHandler}
    />
  );
};

App.displayName = 'App';
App.defaultProps = {};

export default React.memo(App);
