import React, { useState } from 'react';

import { ISize } from '../../../hooks/use-window-size';

import HomeView from './Home.view';

const librariesObj = {
  cni: {text: "CNI", libName: "CNI"},
  capture: {text: "Capture", libName: "CAPTURE"},
  liveness: {text: "Liveness", libName: "LIVENESS"},
  ilId: {text: "Israel ID", libName: "IL-ID"},
  ilDl: {text: "Israel Driving License", libName: "IL-DL"},
  mrz: {text: "MRZ", libName: "MRZ"},
  illw: {text: "Israel Lawyer Card", libName: "IL-LW"},
  ccard: {text: "Credit Card", libName: "C-CARD"},
  ilstp: {text: "Israel Stay Permit", libName: "IL-STP"},
  
};

interface Props { 
  readonly size: ISize;
  readonly startComponentHandler: (caseId: string, libName: string, extraDataAdditions: object, flowConfigName: string) => void;
}

const Home: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const components = Object.values(librariesObj).map((elem) => {
    return elem.text;
  });
  const [flowConfigNameState, setFlowConfigNameState] = useState<string>('mathilda.json')

  const submitSettingsHandler = (newFlowConfigName: string) => setFlowConfigNameState(() => newFlowConfigName);

  const startComponentHandler = (component: string) => {
    let extraDataAdditions = {};
    switch (component) {
      case librariesObj.liveness.text:
        extraDataAdditions = {
          // sttLanguage:'iw-IL',
          // clientTranslationFileName : "scanovate_default_he.json",
        };
        break;
      case librariesObj.ilId.text:
        extraDataAdditions = {
          clientTranslationFileName : "scanovate_default_he.json",
        };
        break;
      default:
        break;
    }

    const libMappedObj = Object.values(librariesObj).filter((elem) => {
      return elem.text === component;
    })[0];

    const caseId = `scanovate_react_demo-${libMappedObj.libName}-${Date.now()}`
    props.startComponentHandler(caseId, libMappedObj.libName, extraDataAdditions, flowConfigNameState);
  }

  return (
    <HomeView
      size={props.size}
      flowConfigName={flowConfigNameState}
      components={components}
      submitSettingsHandler={submitSettingsHandler}
      startComponentHandler={startComponentHandler}
      children={props.children}
      />
  );
};

Home.displayName = 'Home';
Home.defaultProps = {};

export default Home;
