import React, { CSSProperties, useEffect, useState } from 'react';

import useResult from '../../../hooks/use-result';
import { ISize } from '../../../hooks/use-window-size';
import { Dict } from '../../../models/capture';

import ResultView from './Result.view';

interface Props {
  readonly size: ISize;
  readonly showResult: boolean;
  readonly result: Dict | String;
  readonly completeButtonClickHandler: () => void;
 }

const Result: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {

  const {headersState, stagesState} = useResult(props.result);
  const [listStyleState, setListStyleState] = useState<CSSProperties>({})
  const [buttonStyleState, setButtonStyleState] = useState<CSSProperties>({})

  useEffect(() => {
    if (props.size.width > 0 && props.size.height > 0) {
      if (props.size.height * 0.501 + 20 > props.size.width) {
        setListStyleState({
          width: props.size.width - 20,
        });
        setButtonStyleState({
          minHeight: (props.size.width - 20) * 0.175, 
          width: props.size.width - 20,
          borderRadius:props.size.height * 0.015,
        });
      } else {
        setListStyleState({
          width: props.size.height * 0.501,
        });
        setButtonStyleState({
          minHeight: props.size.height * 0.088, 
          width: props.size.height * 0.501,
          borderRadius:props.size.height * 0.015, 
        });
      }
    }
  }, [props.size]);

  return (
    <ResultView
      showResult={props.showResult}
      headers={headersState}
      stages={stagesState}
      buttonStyle={buttonStyleState}
      listStyle={listStyleState}
      completeButtonClickHandler={props.completeButtonClickHandler}
      children={props.children}
      />
  );
};

Result.displayName = 'Result';
Result.defaultProps = {};

export default Result;
