/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import useInterval from '../../../../../../hooks/use-interval';

import GestureOverlayView from './GestureOverlay.view';

interface Props {
  readonly showGestureOverlay: boolean;
  readonly resources: any;
  readonly gestures: string[];
  readonly timeForIcon: number;
  readonly gestureIconPassColor: string;
  readonly finishRecordingHandler: (sendReportSuccess: () => void) => void;
  readonly finishGestureHandler: () => void;
}

const GestureOverlay: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {

  const [currentIconIndexState, setCurrentIconIndexState] = useState<number>(0);
  const [currentCounterState, setCurrentCounterState] = useState<number>(props.timeForIcon);
  const [isPlayingState, setIsPlayingState] = useState<boolean>(false);
  const [evenMaskImageState, setEvenMaskImageState] = useState<string>('');
  const [oddMaskImageState, setOddMaskImageState] = useState<string>('');
  const [evenMaskLineImageState, setEvenMaskLineImageState] = useState<string>('');
  const [oddMaskLineImageState, setOddMaskLineImageState] = useState<string>('');

  const countdownHandler = () => {
    const gesturesKeys =  props.gestures||[];
    if (currentCounterState >= 1) {
      setCurrentCounterState((prev) => prev - 1);
      // const gesturesBaseUrl = (window as any).__PUBLIC_URL__ + '/client/gestures';

      if (currentCounterState === props.timeForIcon && currentIconIndexState > 0) {
        if (currentIconIndexState % 2 === 0 && gesturesKeys.length > currentIconIndexState + 1) {
          setOddMaskImageState(`url(data:image/svg+xml;base64,${props.resources[gesturesKeys[currentIconIndexState + 1]].gesture_mask}), linear-gradient(#000, #000)`);
          setOddMaskLineImageState(`data:image/svg+xml;base64,${props.resources[gesturesKeys[currentIconIndexState + 1]].gesture_mask_line}`);
        } else if (currentIconIndexState % 2 === 1 && gesturesKeys.length > currentIconIndexState + 1) {
          setEvenMaskImageState(`url(data:image/svg+xml;base64,${props.resources[gesturesKeys[currentIconIndexState + 1]].gesture_mask}), linear-gradient(#000, #000)`);
          setEvenMaskLineImageState(`data:image/svg+xml;base64,${props.resources[gesturesKeys[currentIconIndexState + 1]].gesture_mask_line}`);
        }
      }
    } else {
      setCurrentIconIndexState((prev) => prev + 1);
      if (gesturesKeys.length > currentIconIndexState + 1) {
        setCurrentCounterState(props.timeForIcon);
      } else {
        setIsPlayingState(false);
        props.finishRecordingHandler(() => {
          //TODO::Something after server get finish
          props.finishGestureHandler();
        });
      }
    }
  }

  useEffect(() => {
    //const gesturesBaseUrl = (window as any).__PUBLIC_URL__ + '/client/gestures';
    if (props.resources) {
      const gesturesKeys = props.gestures||[];
      if (gesturesKeys.length > 0 && props.resources[gesturesKeys[0]]) {
        setEvenMaskImageState(`url(data:image/svg+xml;base64,${props.resources[gesturesKeys[0]].gesture_mask}), linear-gradient(#000, #000)`);
        setEvenMaskLineImageState(`data:image/svg+xml;base64,${props.resources[gesturesKeys[0]].gesture_mask_line}`);
      }
      if (gesturesKeys.length > 1 && props.resources[gesturesKeys[1]]) {
        setOddMaskImageState(`url(data:image/svg+xml;base64,${props.resources[gesturesKeys[1]].gesture_mask}), linear-gradient(#000, #000)`)
        setOddMaskLineImageState(`data:image/svg+xml;base64,${props.resources[gesturesKeys[1]].gesture_mask_line}`);
      }
    }
  }, [props.resources, props.gestures]);

  useEffect(() => {
    if (props.showGestureOverlay) setIsPlayingState(true);
  }, [props.showGestureOverlay]);

  useEffect(() => {
    if (props.timeForIcon !== currentCounterState) setCurrentCounterState(props.timeForIcon);
  }, [props.timeForIcon]);

  useInterval(countdownHandler, isPlayingState ? 1000 : null);
  return (
    <GestureOverlayView
      showGestureOverlay={props.showGestureOverlay}
      resources={props.resources}
      currentIconIndex={currentIconIndexState}
      gestures={props.gestures}
      currentCounter={isPlayingState ? currentCounterState : ""}
      gestureIconPassColor={props.gestureIconPassColor}
      evenMaskImage={evenMaskImageState}
      oddMaskImage={oddMaskImageState}
      evenMaskLineImage={evenMaskLineImageState}
      oddMaskLineImage={oddMaskLineImageState}
      children={props.children}
      />
  );
};

GestureOverlay.displayName = 'GestureOverlay';
GestureOverlay.defaultProps = {};

export default GestureOverlay;
