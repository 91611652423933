import React, { ReactNode } from "react";


import classes from './CCResultImageCell.module.scss';

interface Props { 
  children?: ReactNode;
  readonly title: string;
  readonly imgSrc: string;
}

const CCResultImageCellView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => (
  <div className={classes['container']}>
    <h1 className={classes['container__key']}>{props.title}</h1>
    <img 
      className={classes['container__val']}
      src={props.imgSrc}
      alt=''
    />
  </div>
);

CCResultImageCellView.displayName = 'CCResultImageCellView';
CCResultImageCellView.defaultProps = {};

export default CCResultImageCellView;
