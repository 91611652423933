/* eslint-disable react-hooks/exhaustive-deps */
import React, { CSSProperties, useEffect, useState } from 'react';

import {
  Dict,
  IImagesCustomizations,
  IRect,
  IStageParams,
  IStylesCustomizations,
  ITitles,
  rectZero,
  StageCodes
} from '../../../../../models/capture';

import CaptureProcessingOverlayView from './CaptureProcessingOverlay.view';

interface Props { 
  readonly cameraPreviewRect: IRect;
  readonly cardRatio: number;
  readonly isLiveness: boolean;
  readonly isRotate90: boolean;
  readonly styles: IStylesCustomizations;
  readonly isConnectWithStream: boolean;
  readonly stageCode: number;
  readonly messageCode: number | null;
  readonly titles: ITitles;
  readonly messages: Dict;
  readonly images: IImagesCustomizations;
  readonly stageOrder: number;
  readonly numOfStages: number;
  readonly stageData?: string;
  readonly maxRecordDuration: number;
  readonly gesturesIcons: string[];
  readonly gesturesResources: any;
  readonly gesturesTimeForIcon: number;
  readonly showPatienceLabel: boolean;
  readonly showX: boolean;
  readonly isUpload: boolean;
  readonly emptyMessageCode: () => void;
  readonly finishRecordingHandler: (sendReportSuccess: () => void) => void;
  readonly backButtonClickHandler: () => void;
}

const CaptureProcessingOverlay: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {

  const [completeOrErrorState, setCompleteOrErrorState] = useState<boolean>(false);
  const [instructionTimeoutState, setInstructionTimeoutState] = useState<any | undefined>(undefined);
  const [checkmarkTimeoutState, setCheckMarkTimeoutState] = useState<any | undefined>(undefined);
  const [animationTimeoutState, setAnimationTimeoutState] = useState<any | undefined>(undefined);
  const [handleRecordTimeoutState, setHandleRecordTimeoutState] = useState<any | undefined>(undefined);
  const [canChangeInstructionTimeoutState, setCanChangeInstructionTimeoutState] = useState<any | undefined>(undefined);
  const [showRecordOverlayState, setShowRecordOverlayState] = useState<boolean>(false);
  const [showGestureOverlayState, setShowGestureOverlayState] = useState<boolean>(false);
  const [showCheckmarkState, setShowCheckmarkState] = useState<boolean>(false);
  const [showLineAnimationState, setShowLineAnimationState] = useState<boolean>(false);
  const [instructionLabelTextState, setInstructionLabelTextState] = useState<string>("");
  const [toastTextState, setToastTextState] = useState<string>("");
  const [currentStageImgSrcState, setCurrentStageImgSrcState] = useState<string | undefined>(undefined);
  const [showToastState, setShowToastState] = useState<boolean>(false);
  const [showStageImgState, setShowStageImgState] = useState<boolean>(false);
  const [canChangeInstructionState, setCanChangeInstructionState] = useState(false);
  const [isFirstStageState, setIsFirstStageState] = useState(true);
  const [isOTPState, setIsOTPState] = useState<boolean>(true);
  const [paintedRectTargetState, setPaintedRectTargetState] = useState<IRect>(rectZero);
  const [showPaintedRectTargetState, setShowPaintedRectTargetState] = useState<boolean>(true);
  const [isTargetWidthGTZeroState, setIsTargetWidthGTZeroState] = useState<boolean>(false);
  const [targetWidthChangeTimeoutState, setTargetWidthChangeTimeoutState] = useState<any | undefined>(undefined);
  const [toastTimeoutState, setToastTimeoutState] = useState<any | undefined>(undefined);
  const [targetLineRectState, setTargetLineRectState] = useState<IRect>(rectZero);
  const [instructionTopState, setInstructionTopStateState] = useState<number>(0);
  const [toastTopState, setToastTopStateState] = useState<number>(0);
  const gesturesInstructionTopState: number = 10; // = useState<number>(10);
  const [stageImgStyleState, setStageImgStyleState] = useState<CSSProperties>({});
  const [codeArrayState, setCodeArrayState] = useState<string[]>([""]);
  const [instructionFontSizeState, setInstructionFontSizeState] = useState<number>(21);
  const [toastFontSizeState, setToastFontSizeState] = useState<string | number>(18);
  const [showSpinnerState, setShowSpinnerState] = useState<boolean>(true);

  useEffect(() => {
    setCompleteOrErrorState(false);

    return () => {
      setCompleteOrErrorState(true);
      cleanAllTimeout()
    }    
  }, []);

  useEffect(() => {
    setShowSpinnerState(!props.isConnectWithStream);    
  }, [props.isConnectWithStream]);

  useEffect(() => {
    props.showPatienceLabel && setToastTextState(props.messages["250"]);
  }, [props.messages])

  useEffect(() => {
    setIsTargetWidthGTZeroState(false);
    targetWidthChangeTimeoutState && clearTimeout(targetWidthChangeTimeoutState);

    if (props.cardRatio > 0 && props.cameraPreviewRect.width > 0 && props.cameraPreviewRect.height > 0) {

      let scanFrameMaxWidth = props.cameraPreviewRect.width;
      let scanFrameMaxHeight = props.cameraPreviewRect.height;

      const isLandscape = scanFrameMaxWidth > scanFrameMaxHeight;

      let frameRectPercent: number;
      let cardRatio = props.cardRatio;
      if (props.isLiveness) {
        frameRectPercent = isLandscape ? 64 : 78.6;
      } else if (props.isRotate90) {
        frameRectPercent = 90;
        if (!isLandscape) cardRatio = 1 / cardRatio;
      } else {
        frameRectPercent = isLandscape ? 75 : 84;
      }

      let heightMAX = scanFrameMaxHeight * frameRectPercent / 100;
      let widthMAX = scanFrameMaxWidth * frameRectPercent / 100;
      let newH = heightMAX;
      let newW = heightMAX * cardRatio;
      if (newW > widthMAX && cardRatio !== 0) {
        newW = widthMAX;
        newH = newW / cardRatio
      }

      let topPaintedRectTarget: number;
      let instructionLabelContainerTop: number;
      let toastTop: number;

      if (isLandscape) {
        setInstructionFontSizeState(Math.min(scanFrameMaxWidth * 0.02, 21));
        setToastFontSizeState(Math.min(scanFrameMaxWidth * 0.018, 18));
        if (props.isLiveness) {
          topPaintedRectTarget = scanFrameMaxHeight / 2 - newH / 2 - newH * 0.05;
          instructionLabelContainerTop = topPaintedRectTarget - newH * 0.2;
          toastTop = topPaintedRectTarget + newH + newH * 0.2;
        } else {
          topPaintedRectTarget = scanFrameMaxHeight / 2 - newH / 2 + scanFrameMaxHeight * 0.1;
          instructionLabelContainerTop = topPaintedRectTarget - scanFrameMaxWidth * 0.1;
          toastTop = topPaintedRectTarget + newH - scanFrameMaxWidth * 0.05;
        }
      } else {
        setInstructionFontSizeState(Math.min(scanFrameMaxHeight * 0.027, 21));
        setToastFontSizeState(Math.min(scanFrameMaxHeight * 0.023, 18));
        if (props.isLiveness) {
          topPaintedRectTarget = scanFrameMaxHeight / 2 - newH / 2 - newH * 0.085
          instructionLabelContainerTop = topPaintedRectTarget - scanFrameMaxHeight * 0.1;
          toastTop = topPaintedRectTarget + newH + scanFrameMaxHeight * 0.1;
        } else if (props.isRotate90) {
          topPaintedRectTarget = scanFrameMaxHeight / 2 - newH / 2;
          instructionLabelContainerTop = topPaintedRectTarget + newH / 3
          toastTop = topPaintedRectTarget + newH + 1;
        } else {
          topPaintedRectTarget = scanFrameMaxHeight / 2 - newH / 2 - newH * 0.15;
          instructionLabelContainerTop = topPaintedRectTarget - scanFrameMaxHeight * 0.1;
          toastTop = topPaintedRectTarget + newH + scanFrameMaxHeight * 0.01;
        }
      }

      const paintedRectTarget:IRect = {
        left: scanFrameMaxWidth / 2 - newW / 2,
        top: topPaintedRectTarget,
        width: newW,
        height: newH,
      }

      let stageImgStyle: CSSProperties;
      if (paintedRectTarget.width > paintedRectTarget.height) {
        if (props.isLiveness)
          stageImgStyle = {
            top: paintedRectTarget.top - ((paintedRectTarget.height * 0.2) / 2),
            left: paintedRectTarget.left + ((paintedRectTarget.width - paintedRectTarget.height * 0.8) / 2),
            height: paintedRectTarget.height * 0.8,
          }
        else
          stageImgStyle = {
            top: paintedRectTarget.top - ((paintedRectTarget.width - paintedRectTarget.height) / 2),
            left: paintedRectTarget.left,
            width: paintedRectTarget.width,
          }
      } else {
        if (props.isLiveness)
          stageImgStyle = {
            top: paintedRectTarget.top + ((paintedRectTarget.height - paintedRectTarget.width * 0.8) / 2),
            left: paintedRectTarget.left + ((paintedRectTarget.width * 0.2) / 2),
            width: paintedRectTarget.width * 0.8,
          }
        else
          stageImgStyle = {
            top: paintedRectTarget.top,
            left: paintedRectTarget.left - ((paintedRectTarget.height - paintedRectTarget.width) / 2),
            height: paintedRectTarget.height,
          }
      }

      setPaintedRectTargetState(paintedRectTarget);
      setToastTopStateState(toastTop)
      setInstructionTopStateState(instructionLabelContainerTop);
      setStageImgStyleState(stageImgStyle);

      setTargetWidthChangeTimeoutState(setTimeout(() => {
        if(paintedRectTarget.width > 0) {
          document.documentElement.style.setProperty('--scanovate-line-animation-end-x', (paintedRectTarget.width - 32) + 'px'); // 32 is 2cr of targetRect;
          setIsTargetWidthGTZeroState(true);
        }
      },251));

      setTargetLineRectState({
        height: paintedRectTarget.height + 4,
        width: paintedRectTarget.height * 0.02,
        left: paintedRectTarget.left + 16,
        top: paintedRectTarget.top,
      });
    }
  }, [props.cameraPreviewRect, props.cardRatio, props.isLiveness, props.isRotate90]);

  useEffect(() => {
    if(showSpinnerState && props.isConnectWithStream) setShowSpinnerState(false);
    if(props.showPatienceLabel) {
      toastTimeoutState && clearTimeout(toastTimeoutState);
      setShowToastState(false);    
    }
    let stageParams: IStageParams;
    switch(props.stageCode){
      case StageCodes.Any:
        stageParams = {
          titleText: props.titles?.SCAN_YOUR_CARD ?? '',
          animationTitleText: props.titles?.TURN_ID_OVER ?? '',
          animationDuration: 2500,
          currentImage: props.images.CardFlipImgSrc,
        }
        startStage(stageParams);
        break;

      case StageCodes.FrontSide:
        stageParams = {
          titleText: props.titles?.SCAN_YOUR_CARD_FRONT_SIDE ?? '',
          animationTitleText: props.titles?.TURN_ID_OVER ?? '',
          animationDuration: 2500,
          currentImage: props.images.CardFlipImgSrc,
        };
        startStage(stageParams);
        break;

      case StageCodes.BackSide:
        stageParams = {
          titleText: props.titles?.SCAN_YOUR_CARD_BACK_SIDE ?? '',
          animationTitleText: props.titles?.TURN_ID_OVER ?? '',
          animationDuration: 2500,
          currentImage: props.images.CardFlipImgSrc,
        };
        startStage(stageParams);
        break;

      case StageCodes.Video:
        stageParams = {
          titleText: props.titles?.TILT_YOUR_CARD ?? '',
          animationTitleText: props.titles?.TILT_YOUR_CARD ?? '',
          animationDuration: 3000,
          currentImage: props.images.TiltCardImgSrc,
        };
        startStage(stageParams);
        break;

      case StageCodes.Passive:
        stageParams = {
          titleText: props.titles?.PASSIVE ?? '',
          animationTitleText: props.titles?.PASSIVE ?? '',
          animationDuration: 2500,
          currentImage: props.images.CenterImgSrc,
        };
        startStage(stageParams);
        break;

      case StageCodes.Center:
        stageParams = {
          titleText: props.titles?.CENTER ?? '',
          animationTitleText: props.titles?.CENTER ?? '',
          animationDuration: 2500,
          currentImage: props.images.CenterImgSrc,
        };
        startStage(stageParams);
        break;

      case StageCodes.Left:
        stageParams = {
          titleText: props.titles?.LEFT ?? '',
          animationTitleText: props.titles?.LEFT ?? '',
          animationDuration: 2500,
          currentImage: props.images.CenterToLeftImgSrc,
        };
        startStage(stageParams);
        break;

      case StageCodes.Right:
        stageParams = {
          titleText: props.titles?.RIGHT ?? '',
          animationTitleText: props.titles?.RIGHT ?? '',
          animationDuration: 2500,
          currentImage: props.images.CenterToRightImgSrc,
        };
        startStage(stageParams);
        break;

      case StageCodes.OTP:
        setIsOTPState(true);
        cleanAllTimeout();
        handleRecord(props.titles?.RECORD_OTP ?? '', false);
        break;

      case StageCodes.STT:
        setIsOTPState(false);
        cleanAllTimeout();
        handleRecord(props.titles?.RECORD_STT ?? '', false);
        break;

      case StageCodes.Gestures:
        cleanAllTimeout();
        handleRecord(props.titles?.RECORD_GESTURE ?? '', true);
        break;

      case StageCodes.Completed:
        cleanAllTimeout();
        setCompleteOrErrorState(true);
        handleCompleted();
        break;

      case StageCodes.Error:
        cleanAllTimeout();
        setCompleteOrErrorState(true);
        handleError();
        break;

      case StageCodes.Timeout:
        cleanAllTimeout();
        setCompleteOrErrorState(true);
        handleTimeout();
        break;

      default:
        break
    }
  }, [props.stageCode]);

  useEffect(() => {
    if (props.messageCode && canChangeInstructionState) {
      setCanChangeInstructionState(false);
      setCanChangeInstructionTimeoutState(setTimeout(() => {
        setCanChangeInstructionState(true);
      }, 1000));
      const message = props.messages[props.messageCode.toString()];
      if (instructionLabelTextState !== message) setInstructionLabelTextState(message);
    }
    props.emptyMessageCode();
  }, [props.messageCode]);

  useEffect(() => {
    let codeArray: string[];

    if (isOTPState) codeArray = props.stageData ? props.stageData.split('') : ["0", "0", "0", "0"];
    else codeArray = props.stageData ? [props.stageData] : [""]
    
    setCodeArrayState(codeArray);

  }, [props.stageData, isOTPState]);

  const startStage = (stageParams: IStageParams) => {
    instructionTimeoutState && clearTimeout(instructionTimeoutState);
    checkmarkTimeoutState && clearTimeout(checkmarkTimeoutState);
    animationTimeoutState && clearTimeout(animationTimeoutState);

    if (props.stageOrder === 1) {
      initStage(stageParams.titleText, false);
      return;
    }
    
    setShowPaintedRectTargetState(true);
    setShowRecordOverlayState(false);
    setShowGestureOverlayState(false);
    setShowCheckmarkState(true);
    setShowLineAnimationState(false);

    const img = new Image();
    img.src = stageParams.currentImage + "?a=" + Math.random();

    new Promise<void>((res) => {
      checkmarkTimeoutState && clearTimeout(checkmarkTimeoutState);
      setCheckMarkTimeoutState(setTimeout(() => {
        setShowCheckmarkState(false);
        if (instructionLabelTextState !== stageParams.animationTitleText) setInstructionLabelTextState(stageParams.animationTitleText);
        setCurrentStageImgSrcState(img.src);
        setShowStageImgState(true);
        res()
      }, 1000));
    }).then(() => {
      animationTimeoutState && clearTimeout(animationTimeoutState);
      setAnimationTimeoutState(setTimeout(() => {
        if (!completeOrErrorState){
          setShowStageImgState(false);
          initStage(stageParams.titleText, false);
        }
      }, stageParams.animationDuration));
    });
  }

  const initStage = (titleText: string, hideLineAnim: boolean) => {
    let instructionTimeoutDuration = 2000;
    if (isFirstStageState) {
      setIsFirstStageState(false);
      instructionTimeoutDuration = 3500;
    }

    if (props.stageCode===StageCodes.Gestures)setShowPaintedRectTargetState(false);
    else setShowPaintedRectTargetState(true);
    
    setCanChangeInstructionState(false);
    instructionTimeoutState && clearTimeout(instructionTimeoutState);
    setInstructionTimeoutState(setTimeout(() => {
      setCanChangeInstructionState(true);
    }, instructionTimeoutDuration));
    if (showCheckmarkState) setShowCheckmarkState(false);
    setShowStageImgState(false);
    if (instructionLabelTextState !== titleText) setInstructionLabelTextState(titleText);
    if (!hideLineAnim) {
      setShowLineAnimationState(true);
      setShowRecordOverlayState(false);
      setShowGestureOverlayState(false);
      props.showPatienceLabel && setToastTimeoutState(setTimeout(() => {
        setShowToastState(true);
      },6000))
    }
  }

  const handleRecord = (title: string, isGesture: boolean) => {
    instructionTimeoutState && clearTimeout(instructionTimeoutState);

    if (props.stageOrder === 1) {
      initStage(title, true);
      if (isGesture) {
        setShowRecordOverlayState(false);
        setShowGestureOverlayState(true);
      } else {
        setShowGestureOverlayState(false);
        setShowRecordOverlayState(true);
      }
      return;
    }

    setShowCheckmarkState(true);
    setShowLineAnimationState(false);

    setHandleRecordTimeoutState(setTimeout(() => {
      setShowCheckmarkState(false);
      if (isGesture) {
        setShowRecordOverlayState(false);
        setShowGestureOverlayState(true);
      } else {
        setShowGestureOverlayState(false);
        setShowRecordOverlayState(true);
      }
      initStage(title, true);
    }, 1000));
  }

  const handleCompleted = () => {
    if (instructionLabelTextState !== props.titles.DONE) setInstructionLabelTextState(props.titles?.DONE ?? '');
    setShowLineAnimationState(false);
    if (!showCheckmarkState) setShowCheckmarkState(true);
  };

  const handleTimeout = () => {
    if (instructionLabelTextState !== props.titles.DONE || instructionLabelTextState !== props.titles.ERROR) setInstructionLabelTextState(props.titles?.ERROR ?? '');
    setShowLineAnimationState(false);
    if (showCheckmarkState) setShowCheckmarkState(false);
  };

  const handleError = () => {
    if (instructionLabelTextState !== props.titles.DONE || instructionLabelTextState !== props.titles.ERROR) setInstructionLabelTextState(props.titles?.ERROR ?? '');
    setShowLineAnimationState(false);
    if (showRecordOverlayState) setShowRecordOverlayState(false);
  };

  const finishGestureHandler = () => setShowSpinnerState(true)

  const cleanAllTimeout = () => {
    instructionTimeoutState && clearTimeout(instructionTimeoutState);
    checkmarkTimeoutState && clearTimeout(checkmarkTimeoutState);
    animationTimeoutState && clearTimeout(animationTimeoutState);
    handleRecordTimeoutState && clearTimeout(handleRecordTimeoutState);
    canChangeInstructionTimeoutState && clearTimeout(canChangeInstructionTimeoutState);
    targetWidthChangeTimeoutState && clearTimeout(targetWidthChangeTimeoutState);
    toastTimeoutState && clearTimeout(toastTimeoutState);
  }

  return (
    <CaptureProcessingOverlayView
      cameraPreviewRect={props.cameraPreviewRect}
      styles={props.styles}
      instructionFontSize={instructionFontSizeState}
      toastFontSize={toastFontSizeState}
      isConnectWithStream={props.isConnectWithStream}
      showPaintedRectTarget={showPaintedRectTargetState}
      paintedRectTarget={paintedRectTargetState}
      showLineAnimation={showLineAnimationState && isTargetWidthGTZeroState}
      targetLineRect={targetLineRectState}
      instructionLabelText={instructionLabelTextState}
      toastText={toastTextState}
      instructionTop={instructionTopState}
      toastTop={toastTopState}
      gesturesInstructionTop={gesturesInstructionTopState}
      progressLabel={`${props.stageOrder}/${props.numOfStages}`}
      checkmarkImgSrc={props.images.Checkmark}
      showToast={showToastState}
      showCheckmark={showCheckmarkState}
      stageImgStyle={stageImgStyleState}
      currentStageImgSrc={currentStageImgSrcState}
      showStageImg={showStageImgState}
      isOTP={isOTPState}
      showRecordOverlay={showRecordOverlayState}
      showGestureOverlay={showGestureOverlayState}
      codeArray={codeArrayState}
      recordingButtonTitle={props.titles?.FINISH_RECORD ?? ''}
      maxRecordDuration={props.maxRecordDuration}
      gesturesResources={props.gesturesResources}
      gesturesIcons={props.gesturesIcons}
      gesturesTimeForIcon={props.gesturesTimeForIcon} 
      showSpinner={showSpinnerState}
      showX={props.showX}
      isUpload={props.isUpload}
      isRotate90={props.isRotate90}
      finishRecordingHandler={props.finishRecordingHandler}
      backButtonClickHandler={props.backButtonClickHandler}
      finishGestureHandler={finishGestureHandler}
      children={props.children}
    />
  );
};

CaptureProcessingOverlay.displayName = 'CaptureProcessingOverlay';
CaptureProcessingOverlay.defaultProps = {};

export default React.memo(CaptureProcessingOverlay);
