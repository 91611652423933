import React, { CSSProperties } from 'react';

import TargetLineAnimationView from './TargetLineAnimation.view';

interface Props { 
  readonly style: CSSProperties;
  readonly show: boolean;
}

const TargetLineAnimation: React.FC<Props> = (props: React.PropsWithChildren<Props>) => (
  <TargetLineAnimationView
    style={props.style}
    show={props.show}
    children={props.children}
    />
);

TargetLineAnimation.displayName = 'TargetLineAnimation';
TargetLineAnimation.defaultProps = {};

export default React.memo(TargetLineAnimation);
