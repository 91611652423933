import React, { CSSProperties, ReactNode } from 'react';
import { motion } from 'framer-motion';

import { visibilityVariant } from '../../../../../models/capture';

import classes from './CaptureToast.module.scss';

interface Props {
  children?: ReactNode;
  readonly toastTop: number;
  readonly toastText: string;
  readonly showToast: boolean;
  readonly toastStyle: CSSProperties;
 }

const CaptureToastView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => (
  <motion.div
    className={classes['container']}
    style={{top: props.toastTop}}
    variants={visibilityVariant("flex" ,0.25, true)}
    initial="hidden"
    animate={props.showToast ? "visible" : "hidden"}
  >
    <h1
      className={classes['container__label']}
      style={props.toastStyle}
      dir='auto'
    >{props.toastText}</h1>
  </motion.div>
);

CaptureToastView.displayName = 'CaptureToastView';
CaptureToastView.defaultProps = {};

export default CaptureToastView;
