import React, { ReactNode } from "react";

import CCGestureSvg, { GestureBarIconState } from '../CCGestureSvg/CCGestureSvg';
import CCSvg from '../CCSvg/CCSvg';

import classes from './CCGestureBarIcon.module.scss';

interface Props { 
  children?: ReactNode;
  readonly iconName: string[];
  readonly state: GestureBarIconState;
  readonly gestureIconPassColor: string;
  readonly counter?: number | string;  
}

const CCGestureBarIconView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {

  const transparentArrowClasses = `${classes['container__arrow']} ${classes['container__arrow--transparent']}`; 
  
  return (
    <div className={classes['container']}>
      <CCSvg
        className={(props.state === GestureBarIconState.Current) ? classes['container__arrow'] : transparentArrowClasses}
        name="arrowR"
      />
      <CCGestureSvg
        className={classes['container__icon']}
        name={props.iconName}
        state={props.state}
        style={props.state === GestureBarIconState.Past ? {fill:props.gestureIconPassColor} : {}}
      />
      <h1 className={classes['container__counter']}>{props.counter}</h1>
    </div>
  );
};

CCGestureBarIconView.displayName = 'CCGestureBarIconView';
CCGestureBarIconView.defaultProps = {};

export default CCGestureBarIconView ;
