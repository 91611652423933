/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import debugModule from "debug";
import axios, { AxiosResponse } from 'axios';

import { ISize } from '../../../../hooks/use-window-size';
import CaptureProcessingView from './CaptureProcessing.view';
import { convertTranslationScanToITitles } from '../../../../utils/utils';
import {
  cameraSize,
  defaultImages,
  defaultMessageTranslations,
  defaultPreStageTranslations,
  defaultStyles,
  Dict,
  IConnectionParams,
  ICPalette,
  IImagesCustomizations,
  IRect,
  IStylesCustomizations,
  ITitles,
  ITranslationRes,
  StageCodes,
} from '../../../../models/capture';
import {
  ServerError,
  ScanovateError,
  CouldNotGetMediaStreamError,
  UserCanceled,
  SessionAbandoned,
} from '../../../../helpers/customErrors';

import ConnectionManager from '../../../../utils/communication';

const debug = debugModule('modular-client:connection-context');

interface Props {
  readonly connectionParams: IConnectionParams;
  readonly size: ISize;
  readonly showPatienceLabel: boolean;
  readonly isKiosk: boolean;
  readonly isRotatePassport: boolean;
  readonly translationPath: string | null;
  readonly onComplete: (res: Dict) => void; // TODO::?? change dict to err interface? change
  readonly onError: (err: Dict) => void; // TODO::?? change dict to err interface?
}

const CaptureProcessing: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {

  const scanovateCon = useMemo(() => new ConnectionManager(props.connectionParams.url, props.connectionParams.extraData, props.connectionParams.relativeUrls, null), [props.connectionParams]);
  const isLiveness: boolean = useMemo(() => props.connectionParams.extraData.libraryName === 'LIVENESS', [props.connectionParams.extraData.libraryName]);
  const isRotate90: boolean = useMemo(() => props.connectionParams.extraData.libraryName === 'PHL-CHEQUES' || props.isRotatePassport, [props.connectionParams.extraData.libraryName, props.isRotatePassport]);

  const [isConnectState, setIsConnectState] = useState<boolean>(false);
  const [cardRatioState, setCardRatioState] = useState<number>(0);
  const [cPaletteState, setCPaletteState] = useState<ICPalette | undefined>(undefined);
  const [needToRecordAudioState, setNeedToRecordAudioState] = useState<boolean>(false);
  const [messageMapState, setMessageMapState] = useState<Dict>(defaultMessageTranslations);
  const [preStageTitlesState, setPreStageTitlesState] = useState<ITitles>(defaultPreStageTranslations);
  const [stylesState, setStylesState] = useState<IStylesCustomizations>(defaultStyles);
  const [imagesState, setImagesState] = useState<IImagesCustomizations>(defaultImages);
  const [cameraFacingModeState, setCameraFacingModeState] = useState<string | undefined>(undefined);
  const [numOfStagesState, setNumOfStagesState] = useState<number>(1);
  const [stagesState, setStagesState] = useState<string[]>([]);
  const [stageCodeState, setStageCodeState] = useState<number>(StageCodes.Empty);
  const [stageTimeoutState, setStageTimeoutState] = useState<any | undefined>(undefined);
  const [needNewFrameState, setNeedNewFrameState] = useState<boolean>(false);
  const [frameErrorsCountState, setFrameErrorsCountState] = useState<number>(0);
  const [stageOrderState, setStageOrderState] = useState<number>(1);
  const [stageDataState, setStageDataState] = useState<string | undefined>(undefined);
  const [maxRecordDurationState, setMaxRecordDurationState] = useState<number>(55);
  const [doNotProcessFrameState, setDoNotProcessFrameState] = useState<boolean>(false);
  const [messageCodeState, setMessageCodeState] = useState<number | null>(null);
  const [startAudioState, setStartAudioState] = useState<boolean>(false);
  const [streamState, setStreamState] = useState<MediaStream | undefined>(undefined);
  const [gesturesIconsState, setGesturesIconsState] = useState<string[]>([]);
  const [gesturesResourcesState, setGesturesResourcesState] = useState<any>();
  const [gesturesTimeForIconState, setGesturesTimeForIconState] = useState<number>(5);
  const [showXState, setShowXState] = useState<boolean>(false);
  const [isUploadState, setIsUploadState] = useState<boolean>(false);
  const [needToRecordVideoState, setNeedToRecordVideoState] = useState<boolean>(false);
  const [cameraPreviewRectState, setCameraPreviewRectState] = useState<IRect>({
    width:props.size.width,
    height:props.size.height,
    top: 0,
    left: 0,
  });
  const [mimeTypeState, setMimeTypeState] = useState<string | null>(null);
  const [scanResponseState, setScanResponseState] = useState<any>({});

  const [videoPartsState, setVideoPartsState] = useState<Blob[]>([]);
  const [numOfPartsState, setNumOfPartsState] = useState<number>(0);
  const [currentVideoPartState, setCurrentVideoPartState] = useState<number>(0);
  const [canSendVideoPartState, setCanSendVideoPartState] = useState<boolean>(false);

  let numOfSendVideoPartRetries = 0;
  const MAX_NUM_OF_SEND_VIDEO_PART_RETRIES = 2;

  const getSupportedMimeType = useCallback(() => {
    if (MediaRecorder.isTypeSupported("video/webm")) return "webm";
    else if (MediaRecorder.isTypeSupported("video/mp4")) return "mp4";
    else return null;
  },[]);

  let canSendCallbackState = true;
  let translationPath = props.translationPath

  useEffect(() => {
    if (translationPath) getTranslation();
    else connect();

    document.addEventListener(getVisibilitychangeEvent(),onSessionAbandoned);   

    return () => {
      document.removeEventListener(getVisibilitychangeEvent(), onSessionAbandoned);
      stageTimeoutState && clearTimeout(stageTimeoutState);
    }
  }, []);
  
  useEffect(() => {
    const isLandscape = props.size.width > props.size.height;
      if (isLandscape && props.size.width > 1024 && props.size.height > 553) {
        setCameraPreviewRectState({
          width: 894,
          height: 503,
          top: 50,
          left: (props.size.width / 2) - 447,
        });
      } else {
        let cameraRatio = 0;
        if (props.isKiosk && isLiveness) {
          cameraRatio = isLandscape ? cameraSize.kioskLivenessBigSize / cameraSize.kioskLivenessSmallSize : cameraSize.kioskLivenessSmallSize / cameraSize.kioskLivenessBigSize;
        }
        else {
          cameraRatio = isLandscape ? cameraSize.bigSize / cameraSize.smallSize : cameraSize.smallSize / cameraSize.bigSize;
        }

        let newH = props.size.height;
        let newW = props.size.height * cameraRatio;
        let newTop = 0;
        let newLeft = (props.size.width / 2) - (newW / 2);

        if (newW > props.size.width && cameraRatio !== 0) {
            newW = props.size.width;
            newH = newW / cameraRatio;
            newTop = (props.size.height / 2) - (newH / 2);
            newLeft = 0;
        }

        setCameraPreviewRectState({
          width: newW,
          height: newH,
          top: newTop,
          left: newLeft,
        });
      }
  }, [props.size]);
  
  useEffect(() => {
    if (needToRecordAudioState && !startAudioState && streamState) {
      scanovateCon.startAudioRecorder(onSendAudioOrErrorCallback, {}, streamState);
      setStartAudioState(true);
    }
  }, [needToRecordAudioState, startAudioState, streamState]);

  const connect = async () => {
    scanovateCon.connect({timeout: 20000}).then((res: any) => {// TODO:: change any to doronRes
      if (res.success) {
        handleConnect(res);
        setIsConnectState(true);
        setNeedNewFrameState(true);
        setStageOrderState(1);
      } else handleError(res);
    }, (err: any) => {
      handleError(err);
    });
  }

  const getTranslation = useCallback(async () => {
    try {  
      const res: AxiosResponse<ITranslationRes> = await axios.get(props.translationPath!);
      setMessageMapState(() => res.data.generalMessages);
      setPreStageTitlesState(() => convertTranslationScanToITitles(res.data.scan) as ITitles);
    } catch(e) {
      translationPath = null;
    } finally {
      connect();
    }
  },[props.translationPath]);

  const handleConnect = (res: any) => { // TODO:: change any to doronRes
    if (res.stageData) {
      if (res.stageData.otp_number) setStageDataState(res.stageData.otp_number);
      if (res.stageData.maxRecordDuration) setMaxRecordDurationState(res.stageData.maxRecordDuration);
      if (res.stageData.stt_text) setStageDataState(res.stageData.stt_text);
      if (res.stageData.resources) setGesturesResourcesState(res.stageData.resources);
      if (res.stageData.gestures) setGesturesIconsState(res.stageData.gestures);
      if (res.stageData.sequenceSecondsInterval) setGesturesTimeForIconState(res.stageData.sequenceSecondsInterval);
    }
    if (res.cardRatio) setCardRatioState(res.cardRatio);
    if (res.cPalette) {
      let tempCPalette = res.cPalette.replace('#', '');
      tempCPalette = tempCPalette.match(new RegExp('.{1,' + (tempCPalette.length / 4) + '}', 'g'));
      const colorObj: ICPalette = {
        topLeft: '#' + tempCPalette[0],
        topRight: '#' + tempCPalette[1],
        bottomRight: '#' + tempCPalette[2],
        bottomLeft: '#' + tempCPalette[3],
      };
      setCPaletteState(() => colorObj);
    }
    if (res.audio) setNeedToRecordAudioState(res.audio);
    if (!translationPath && res.messageTranslations) setMessageMapState((prev) => ({...prev, ...res.messageTranslations}));
    if (!translationPath && res.preStageTranslations) setPreStageTitlesState((prev) => ({...prev, ...res.preStageTranslations}));
    if (res.styles) setStylesState((prev) => ({...prev, ...res.styles}));
    if (res.images) setImagesState((prev) => ({...prev, ...res.images}));
    if (res.cameraFacingMode) setCameraFacingModeState(res.cameraFacingMode);
    if (res.stages && res.stages.length > 0) {
      handleStageType(res.stages[0]);
      setNumOfStagesState(res.stages.length);
      setStagesState(res.stages);
    }
    setShowXState(!(res.hideXInClient === true || res.hideXInClient === "true"));
    if (res.recordVideoInClient === true || res.recordVideoInClient === "true") {
      setMimeTypeState(getSupportedMimeType());
      setNeedToRecordVideoState(true);
    }
  }

  const handleError = (err: any) => { //TODO:: change any to doronError.
    onSendFrameCallback = () => {
    };
    document.removeEventListener(getVisibilitychangeEvent(),onSessionAbandoned);

    setStageCodeState(StageCodes.Error);
    stageTimeoutState && clearTimeout(stageTimeoutState);
    scanovateCon.stopAudioRecording();
    setNeedNewFrameState(false);

    if (err !instanceof ScanovateError && typeof err === "object" ) err = new ServerError(err.message ? err.message : 'empty error message', err.code ? err.code : -1);

    if (canSendCallbackState) {
      canSendCallbackState = false;
      props.onError(err);
    }
  }

  const sendVideoParts = (videoParts: Blob[]) => {
    setVideoPartsState(videoParts);
    setNumOfPartsState(videoParts.length);
    setCanSendVideoPartState(true)
  }

  useEffect(() => {
    if (canSendVideoPartState && numOfPartsState > 0 && currentVideoPartState < numOfPartsState) {
      setCanSendVideoPartState(false);
      const blob = new Blob([videoPartsState[currentVideoPartState]], {
        type: `video/${mimeTypeState}`
      });
      scanovateCon.sendPartVideo(onSendVideoCallback, {timeout: 200000}, blob, mimeTypeState, currentVideoPartState + 1, numOfPartsState)
    }
  },[canSendVideoPartState, numOfPartsState, currentVideoPartState, videoPartsState, scanovateCon, mimeTypeState]);

  const sendFrame = (frame: Blob) => {
    setNeedNewFrameState(false);
    let frameToSend = frame;

    // for automation
    //@ts-ignore
    if (window.getOutsideInjectedImage) frameToSend = window.getOutsideInjectedImage();

    scanovateCon.sendFrame(onSendFrameCallback, {
      timeout: 15000,
      doNotProcessFrame: doNotProcessFrameState
    }, /*window.doron_file ||*/ frameToSend);
  };

  const sendReportFailure = (err: any) => { //TODO:: change any to doronError.
    setNeedNewFrameState(false);
    scanovateCon.stopAudioRecording();
    const failureObject = {
      errorCode: err.code,
      errorMessage: err.message,
    };
    scanovateCon.sendReportFailure(onSendReportFailureCallback(err), {timeout: 15000}, failureObject);
  }

  const onSendReportFailureCallback = (err: any) => { //TODO:: change any to doronError.
    return (res: any) => { // ??any
      if (res instanceof Error || (typeof res === 'object' && res.success === false)) debug('onSendReportFailureCallback err: ', err);
      handleError(err);
    }
  }

  let onSendFrameCallback = (res: any) => { // TODO:: change any to doronRes
    setNeedNewFrameState(true);
    if (res instanceof Error || (typeof res === 'object' && res.success === false)) {
      if (frameErrorsCountState > 2 || res.code === 'ERR_NETWORK') {
        if (res instanceof Error) sendReportFailure(res);
        else handleError(res);
      } else {
        setFrameErrorsCountState((prev) => prev + 1);
      }
    } else {
      setFrameErrorsCountState(0);
      if (res) handleFrameRes(res);
      else {
        if (stageCodeState === StageCodes.Error || stageCodeState === StageCodes.Completed) return;
      }
    }
  }

  const onSendVideoCallback = (res: any) => {
    if (res instanceof Error || (typeof res === 'object' && res.success === false)) {
      if (numOfSendVideoPartRetries < MAX_NUM_OF_SEND_VIDEO_PART_RETRIES) {
        numOfSendVideoPartRetries += 1
        const blob = new Blob([videoPartsState[currentVideoPartState]], {
          type: `video/${mimeTypeState}`
        });

        scanovateCon.sendPartVideo(onSendVideoCallback, {timeout: 200000}, blob, mimeTypeState, currentVideoPartState + 1, numOfPartsState)
      } else {
        // const myErr = new RequestVideoError();
    
        setStageCodeState(StageCodes.Error);
    
        if (canSendCallbackState) {
          canSendCallbackState = false;
          props.onError(res);
        }
    
        // sendReportFailure(res);
      }
    }
    else {
      numOfSendVideoPartRetries = 0

      console.log (`onSendVideoCallback part ${currentVideoPartState + 1} of ${numOfPartsState}`)

      if (canSendCallbackState && stageCodeState === StageCodes.Completed && currentVideoPartState + 1 === numOfPartsState) {
        setIsUploadState(false);
        canSendCallbackState = false;
        props.onComplete(scanResponseState);
      }
      else {
        setCurrentVideoPartState((prev) => prev + 1);
        setCanSendVideoPartState(true)
      }
      
    }
  }

  const onSendAudioOrErrorCallback = (res: any) => { // TODO:: change any to doronRes
    if (res instanceof Error || (typeof res === 'object' && res.success === false)) handleError(res);
  }

  const handleFrameRes = (res: any) => { // TODO:: change any to doronRes
    if (res.action_type === 'stage') {
      if (stageCodeState === StageCodes.Error || stageCodeState === StageCodes.Completed) return;
      setStageOrderState(res.stage.order);

      if (res.stageData) {
        if (res.stageData.otp_number) setStageDataState(res.stageData.otp_number);
        if (res.stageData.stt_text) setStageDataState(res.stageData.stt_text);
        if (res.stageData.maxRecordDuration) setMaxRecordDurationState(res.stageData.maxRecordDuration);
        if (res.stageData.resources) setGesturesResourcesState(res.stageData.resources);
        if (res.stageData.gestures) setGesturesIconsState(res.stageData.gestures);
        if (res.stageData.sequenceSecondsInterval) setGesturesTimeForIconState(res.stageData.sequenceSecondsInterval);
      }

      handleStageType(res.stage.type);
      setDoNotProcessFrameState(true);

      stageTimeoutState && clearTimeout(stageTimeoutState);

      setStageTimeoutState(setTimeout(() => {
        if ([StageCodes.Error, StageCodes.Completed].includes(stageCodeState)) return;
        setDoNotProcessFrameState(false);
      }, 4000));
    } else if (res.action_type === 'message') {
      if (stageCodeState === StageCodes.Error || stageCodeState === StageCodes.Completed) return;
      res.message && res.message.id && setMessageCodeState(res.message.id);
    } else if (res.action_type === 'complete') {
      document.removeEventListener(getVisibilitychangeEvent(),onSessionAbandoned);
      setNeedNewFrameState(false);
      scanovateCon.stopAudioRecording();

      if (res.status === 'success') setStageCodeState(StageCodes.Completed);
      else setStageCodeState(StageCodes.Timeout);

      stageTimeoutState && clearTimeout(stageTimeoutState);

      if (needToRecordVideoState && res.status === 'success') {
        setScanResponseState(res);
        setIsUploadState(true);
      }
      else if (canSendCallbackState) {
        canSendCallbackState = false;
        props.onComplete(res);
      }
    } else {
      if (stageCodeState === StageCodes.Error || stageCodeState === StageCodes.Completed) return;
    }
  }

  const handleStageType = (type: string) => { //TODO:: doronEnum
    switch (type) {
      case 'any':
        setStageCodeState(StageCodes.Any);
        break;
      case 'back':
        setStageCodeState(StageCodes.BackSide);
        break;
      case 'front':
        setStageCodeState(StageCodes.FrontSide);
        break;
      case 'video':
        setStageCodeState(StageCodes.Video);
        break;
      case 'passive':
        setStageCodeState(StageCodes.Passive);
        break;
      case 'center':
        setStageCodeState(StageCodes.Center);
        break;
      case 'left':
        setStageCodeState(StageCodes.Left);
        break;
      case 'right':
        setStageCodeState(StageCodes.Right);
        break;
      case 'otp':
        setStageCodeState(StageCodes.OTP);
        break;
      case 'stt':
        setStageCodeState(StageCodes.STT);
        break;
      case 'gestures':
        setStageCodeState(StageCodes.Gestures);
        break;
      default:
        break;
    }
  };

  const onUserMedia = (stream: MediaStream) => setStreamState(stream);
  
  const onUserMediaError = (error: any) => { //TODO not work with string | DOMException
    debug(`onUserMedia error, message:  ${error && error.message}, stack: ${error && error.stack}`);
    if (!(error instanceof ScanovateError) && typeof error === 'object') {
      error = new CouldNotGetMediaStreamError(error.name, error.message);
    }
    sendReportFailure(error);
  }

  const emptyMessageCode = () => setMessageCodeState(null);

  const finishRecordingHandler = (sendReportSuccess: () => void) => {
    let stageName = "";
    if (stageCodeState === StageCodes.OTP) stageName = 'otp';
    else if (stageCodeState === StageCodes.STT) stageName = 'stt';
    else if (stageCodeState === StageCodes.Gestures) stageName = 'gestures';

    const index = stagesState.findIndex(value => value === stageName);
    if (index === -1) return;

    const sendingObj = {
      stageName,
      stageOrdinal: index + 1,
    };

    scanovateCon.sendReportStageEnding((res: any) => { //TODO:: doronResponse
      if (!res.success) {
        handleError(res);
        return;
      }
      sendReportSuccess();
    }, {timeout: 15000}, sendingObj);
  }

  const backButtonClickHandler = () => {
    const myErr = new UserCanceled();
    
    onSendFrameCallback = () => {
    };

    setStageCodeState(StageCodes.Error);

    if (canSendCallbackState) {
      canSendCallbackState = false;
      props.onError(myErr);
    }

    sendReportFailure(myErr);
  }

  const getVisibilitychangeEvent = () => {
    if (typeof document.hidden !== "undefined") return "visibilitychange";
    else if (typeof (document as any).msHidden !== "undefined") return "msvisibilitychange";
    else if (typeof (document as any).webkitHidden !== "undefined") return "webkitvisibilitychange";
    else return "";
  }

  const onSessionAbandoned = () => {
    if (document.hidden || (document as any).msHidden || (document as any).webkitHidden) {

      const myErr = new SessionAbandoned();
      
      onSendFrameCallback = () => {
      };

      setStageCodeState(StageCodes.Error);

      if (canSendCallbackState) {
          canSendCallbackState = false;
          props.onError(myErr);
      }

      sendReportFailure(myErr);
    }
  }

  return (
    <CaptureProcessingView
      cameraPreviewRect={cameraPreviewRectState}
      isLiveness={isLiveness}
      isKiosk={props.isKiosk}
      isRotate90={isRotate90}
      isConnect={isConnectState}
      stream={streamState}
      needToRecordAudio={needToRecordAudioState}
      needToRecordVideo={needToRecordVideoState}
      cameraFacingMode={cameraFacingModeState}
      stageCode={stageCodeState}
      messageCode={messageCodeState}
      cPalette={cPaletteState}
      needNewFrame={needNewFrameState}
      cardRatio={cardRatioState}
      styles={stylesState}
      titles={preStageTitlesState}
      messages={messageMapState}
      images={imagesState}
      stageOrder={stageOrderState}
      numOfStages={numOfStagesState}
      stageData={stageDataState}
      maxRecordDuration={maxRecordDurationState}
      gesturesResources={gesturesResourcesState}
      gesturesIcons={gesturesIconsState}
      gesturesTimeForIcon={gesturesTimeForIconState}
      showPatienceLabel={props.showPatienceLabel}
      showX={showXState}
      isUpload={isUploadState}
      mimeType={mimeTypeState}
      finishRecordingHandler={finishRecordingHandler}
      onUserMedia={onUserMedia}
      onUserMediaError={onUserMediaError}
      sendFrame={sendFrame}
      emptyMessageCode={emptyMessageCode}
      backButtonClickHandler={backButtonClickHandler}
      sendVideoParts={sendVideoParts}
      children={props.children}
    />
  );
};

CaptureProcessing.displayName = 'CaptureProcessing';
CaptureProcessing.defaultProps = {};

export default CaptureProcessing;
