import React, { ReactNode } from "react";

import classes from './CCResultDataCell.module.scss';

interface Props { 
  children?: ReactNode
  readonly title: string;
  readonly message: string;
}

const CCResultDataCellView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => (
  <div
    className={classes['container']}
    // style={{height: props.title.length > 20 || props.message.length > 20 ? 100 : 50}}
  >
    <h1 className={classes['container__key']}>{props.title}</h1>
    <h1 className={classes['container__val']}>{props.message}</h1>
  </div>
);

CCResultDataCellView.displayName = 'CCResultDataCellView';
CCResultDataCellView.defaultProps = {};

export default CCResultDataCellView;
