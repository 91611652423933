import React, { ReactNode } from "react";
import Webcam from 'react-webcam';

import { IRect } from '../../../../../models/capture';

import classes from './SingleCaptureWebcam.module.scss';

interface Props { 
  children?: ReactNode;
  readonly cameraPreviewRect: IRect;
  readonly webcamRef?: React.Ref<Webcam>;
  readonly onUserMedia: (stream: MediaStream) => void;
  readonly onUserMediaError: (error: string | DOMException) => void;
}

const SingleCaptureWebcamView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  
  const videoConstraints = {
    tag: "SNWebcam", 
    width: { min: 1280, ideal: 3840 },
    height: { min: 720, ideal: 2160 },
    facingMode: "environment"
  };
  
  return (
    <>
      <Webcam
        className={classes['container']}
        style={{
          top: props.cameraPreviewRect.top,
          left: props.cameraPreviewRect.left,
        }}
        height={props.cameraPreviewRect.height}
        width={props.cameraPreviewRect.width}
        ref={props.webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
        onUserMedia={props.onUserMedia}
        onUserMediaError={props.onUserMediaError}
        forceScreenshotSourceSize={true}
        screenshotQuality={0.97}
      />
    </>
  );
};

SingleCaptureWebcamView.displayName = 'SingleCaptureWebcamView';
SingleCaptureWebcamView.defaultProps = {};

export default SingleCaptureWebcamView;
