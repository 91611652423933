import React, { CSSProperties } from 'react';

import CaptureToastView from './CaptureToast.view';

interface Props {
  readonly toastTop: number;
  readonly toastText: string;
  readonly showToast: boolean;
  readonly toastStyle: CSSProperties;
 }

const CaptureToast: React.FC<Props> = (props: React.PropsWithChildren<Props>) => (
  <CaptureToastView
    toastTop={props.toastTop}
    toastText={props.toastText}
    showToast={props.showToast}
    toastStyle={props.toastStyle}
    children={props.children}
    />
);

CaptureToast.displayName = 'CaptureToast';
CaptureToast.defaultProps = {};

export default CaptureToast;
