const Emitter = require('component-emitter');

class RecorderService extends Emitter {
  /**
   * RecorderService constructor.
   *
   * @api public
   */
  constructor () {
    super();

    window.AudioContext = window.AudioContext || window.webkitAudioContext;

    this.em = document.createDocumentFragment();

    this.state = RecorderService.states.inactive;

    this.chunks = [];
    this.chunkType = '';

    this.encoderMimeType = 'audio/wav';
  }

  static createWorker (fn) {
    var js = fn
        .toString()
        .replace(/^function\s*\(\)\s*{/, '')
        .replace(/}$/, '');
    var blob = new Blob([js]);
    return new Worker(URL.createObjectURL(blob))
  }

  // Doron added functionality
  _createAudioContextIfLacking() {
    if (!this.audioCtx) {
      const myAudioContext = window.AudioContext || window.webkitAudioContext;
      this.audioCtx = new myAudioContext();
    }
  }
  /**
   * RecorderService constructor.
   *
   * @param {Object} stream - Stream from which to get audio tracks
   * @api public
   */
  startRecording (stream) {
    this.chunks = [];
    this.chunkType = '';

    if (this.state !== RecorderService.states.inactive) {
      return
    }

    // This is the case on ios/chrome, when clicking links from within ios/slack (sometimes), etc.
    if (!navigator || !navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      // TODO replace alert
      //alert('Missing support for navigator.mediaDevices.getUserMedia'); // temp: helps when testing for strange issues on ios/safari
      return
    }

    this._createAudioContextIfLacking();
    this.audioCtx = new AudioContext();
    this.micGainNode = this.audioCtx.createGain();
    this.outputGainNode = this.audioCtx.createGain();

    // Create stream destination on chrome/firefox because, AFAICT, we have no other way of feeding audio graph output
    // in to MediaRecorderSafari/Edge don't have this method as of 2018-04.
    if (this.audioCtx.createMediaStreamDestination) {
      this.destinationNode = this.audioCtx.createMediaStreamDestination();
    }
    else {
      this.destinationNode = this.audioCtx.destination;
    }

    // Setup media constraints
    const userMediaConstraints = {
      audio: {
        echoCancellation: true
      }
    };

    if (stream){
      // TODO think it over
      return Promise.resolve(this._startRecordingWithStream(stream, 1000));
    } else {
      // This will prompt user for permission if needed
      return navigator.mediaDevices.getUserMedia(userMediaConstraints)
          .then((stream) => {
            this._startRecordingWithStream(stream, 1000);
          })
          .catch((error) => {
            // TODO replace alert
            // alert('Error with getUserMedia: ' + error.message); // temp: helps when testing for strange issues on ios/safari
            console.log(error);
          });
    }
  }

  _startRecordingWithStream (stream, timeSlice) {
    this.micAudioStream = stream;

    this._createAudioContextIfLacking();
    this.inputStreamNode = this.audioCtx.createMediaStreamSource(this.micAudioStream);
    this.audioCtx = this.inputStreamNode.context;

    // Kind-of a hack to allow hooking in to audioGraph inputStreamNode
    if (this.onGraphSetupWithInputStream) {
      this.onGraphSetupWithInputStream(this.inputStreamNode);
    }

    this.inputStreamNode.connect(this.micGainNode);
    this.micGainNode.gain.setValueAtTime(1.0, this.audioCtx.currentTime);

    let nextNode = this.micGainNode;
    if (this.dynamicsCompressorNode) {
      this.micGainNode.connect(this.dynamicsCompressorNode);
      nextNode = this.dynamicsCompressorNode;
    }

    this.state = RecorderService.states.recording;

    if (this.processorNode) {
      nextNode.connect(this.processorNode);
      this.processorNode.connect(this.outputGainNode);
      // this.processorNode.onaudioprocess = (e) => {};
    }
    else {
      nextNode.connect(this.outputGainNode);
    }

    if (this.analyserNode) {
      // TODO: If we want the analyser node to receive the processorNode's output, this needs to be changed _and_
      //       processor node needs to be modified to copy input to output. It currently doesn't because it's not
      //       needed when doing manual encoding.
      // this.processorNode.connect(this.analyserNode)
      nextNode.connect(this.analyserNode);
    }

    this.outputGainNode.connect(this.destinationNode);

    this.mediaRecorder = new MediaRecorder(this.destinationNode.stream);
    this.mediaRecorder.addEventListener('dataavailable', (evt) => this._onDataAvailable(evt));
    this.mediaRecorder.addEventListener('error', (evt) => this._onError(evt));

    this.mediaRecorder.start(timeSlice);
  }

  stopRecording () {
    if (this.state === RecorderService.states.inactive) {
      return
    }
    this.state = RecorderService.states.inactive;
    this.mediaRecorder.stop();
  }

  // Doron
  getBlobOfSomeChunks(numOfChunks){
    const nChunks = numOfChunks || this.chunks.length;
    const chunksArr = [];
    let totalChunksSize = 0;
    this.chunks.forEach((chunk) => {
      // console.log(`Doron chunk size: ${chunk.size}`);
      if(chunk && chunk.size){
        totalChunksSize += chunk.size;
      }
    });
    if(totalChunksSize < 1000){
      return null;
    }
    for(let i = 0; i < nChunks; i++){
      const firstChunk = this.chunks.shift();
      if(firstChunk){
        chunksArr.push(firstChunk)
      } else {
        break;
      }
    }
    if(chunksArr.length > 0){
      const retObj = {blob: new Blob(chunksArr, { 'type': this.chunkType }), timestamp: this.blobDate};
      this.blobDate = null;
      return retObj;
    } else {
      return null;
    }
  }

  _onDataAvailable (evt) {
    // console.log('state', this.mediaRecorder.state)
    // console.log('evt.data', evt.data)

    if (this.state !== RecorderService.states.inactive) {
      this.blobDate = this.blobDate || (Date.now() - 1000);
      this.chunks.push(evt.data);
      this.chunkType = evt.data.type;
      this.emit('ondataavailable');
      return
    }
    // let blob = new Blob(this.chunks, { 'type': this.chunkType });
    // let blobUrl = URL.createObjectURL(blob);
    // const recording = {
    //   ts: new Date().getTime(),
    //   blobUrl: blobUrl,
    //   mimeType: blob.type,
    //   size: blob.size
    // };

    if (this.destinationNode) {
      this.destinationNode.disconnect();
      this.destinationNode = null;
    }
    if (this.outputGainNode) {
      this.outputGainNode.disconnect();
      this.outputGainNode = null;
    }
    if (this.analyserNode) {
      this.analyserNode.disconnect();
      this.analyserNode = null;
    }
    if (this.processorNode) {
      this.processorNode.disconnect();
      this.processorNode = null;
    }
    if (this.encoderWorker) {
      this.encoderWorker.postMessage(['close']);
      this.encoderWorker = null;
    }
    if (this.dynamicsCompressorNode) {
      this.dynamicsCompressorNode.disconnect();
      this.dynamicsCompressorNode = null;
    }
    if (this.micGainNode) {
      this.micGainNode.disconnect();
      this.micGainNode = null;
    }
    if (this.inputStreamNode) {
      this.inputStreamNode.disconnect();
      this.inputStreamNode = null;
    }


    // This removes the red bar in iOS/Safari
    // this.micAudioStream.getTracks().forEach((track) => track.stop());
    this.micAudioStream.getAudioTracks().forEach((track) => track.stop());

    this.micAudioStream = null;

    this.audioCtx.close();
    this.audioCtx = null;


    // Doron removed
    // this.em.dispatchEvent(new CustomEvent('recording', { detail: { recording: recording } }))
  }

  _onError (evt) {
    console.log('error', evt);
    this.emit('error', evt);
    // this.em.dispatchEvent(new Event('error'));
    // alert('error:' + evt); // for debugging purposes
  }
}

RecorderService.states = {
  inactive: 'inactive',
  recording: 'recording'
};

export default RecorderService;