import React, { useState } from 'react';

import SettingsDialogView from './SettingsDialog.view';

interface Props { 
  readonly flowConfigName: string;
  readonly submitSettingsHandler: (flowConfigName: string) => void;
}

const SettingsDialog: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const [isOpenState, setIsOpenState] = useState<boolean>(false);
  const [flowConfigNameState,setFlowConfigNameState] = useState<string>(props.flowConfigName);

  const dialogOpenHandler = () => setIsOpenState(() => true);

  const dialogCloseHandler = () => setIsOpenState(() => false);

  const flowConfigChangedHandler = (newName: string) => setFlowConfigNameState(() => newName);

  const submitSettingsHandler = () => {
    props.submitSettingsHandler(flowConfigNameState);
    dialogCloseHandler();
  }

  return (
    <SettingsDialogView
      isOpen={isOpenState}
      flowConfigName={props.flowConfigName}
      dialogOpenHandler={dialogOpenHandler}
      dialogCloseHandler={dialogCloseHandler}
      flowConfigChangedHandler={flowConfigChangedHandler}
      submitSettingsHandler={submitSettingsHandler}
      children={props.children}
      />
  );
};

SettingsDialog.displayName = 'SettingsDialog';
SettingsDialog.defaultProps = {};

export default SettingsDialog;
